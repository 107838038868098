import React, { useState } from "react";
import "./NewsStoriesCard.css";
import PopUp from "../PopUp/PopUp";
import { useNavigate } from "react-router-dom";

function NewsStoriesCard(props) {
  const navigate = useNavigate();
  const handleopen = (desc, title, image) => {
    props.action(desc, title, image);
  };

  return (
    <div className="Main_stories_div">
      <div className="Main_cards_container">
        <div className="Stories_img_container">
          <img src={props.image} draggable={false} className="Stories_img" />
        </div>
        <div className="Stories_text_container">
          <div className="Stories_text1">{props.title}</div>
          <div className="Stories_text2"> {props.date}</div>
          <div
            className="text-container3"
            onClick={() => navigate(`/Storyandnewmodal/${props.id}`)}
          >
            {" "}
            READ MORE
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsStoriesCard;
