import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  subcategories: [],

  loading: false,
  error: null,
};

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    CategoryRequest(state, action) {
      state.loading = true;
      state.categories = [];
      state.error = null;
    },
    CategorySuccess(state, action) {
      state.loading = false;
      state.categories = action.payload;
      state.error = null;
    },
    CategoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.categories = [];
    },


    SubCategoryRequest(state, action) {
      state.loading = true;
      state.subcategories = [];
      state.error = null;
    },
    SubCategorySuccess(state, action) {
      state.loading = false;
      state.subcategories = action.payload;
      state.error = null;
    },
    SubCategoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.subcategories = [];
    },

  },
});

export const CategoryAction = CategorySlice.actions;
export default CategorySlice;
