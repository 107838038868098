import axios from "axios";
import { BillingAction } from "./BillingReducers";

export const GetBillings = () => async (dispatch) => {
  try {
    dispatch(BillingAction.BillingRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/billing/get"
    );
    dispatch(BillingAction.BillingSuccess(data.data));
  } catch (error) {
    dispatch(BillingAction.BillingFail(error));
  }
};

export const GetBillingById = (Billing_id) => async (dispatch) => {
  try {
    dispatch(BillingAction.BillingByIdRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API + "/billing/" + Billing_id
    );
    dispatch(BillingAction.BillingByIdSuccess(data.data));
  } catch (error) {
    dispatch(BillingAction.BillingByIdFail(error));
  }
};

export const EditBilling =
  (Billing_id,first_name,
    last_name,
    mobile,
    country,
    city,
    street_address,
    building_name,
    zipcode,
    region,) => async (dispatch) => {
    try {
      dispatch(BillingAction.BillingRequest());
      const { data } = await axios.patch(
        process.env.REACT_APP_API + "/billing/" + Billing_id,
        {
          street_address: street_address,
          region: region,
          city: city,
          country: country,
          first_name: first_name,
          last_name: last_name,
          mobile: mobile,
          building_name: building_name,
          zipcode: zipcode,
        }
      );
      dispatch(BillingAction.BillingSuccess(data.data));
    } catch (error) {
      dispatch(BillingAction.BillingFail(error));
    }
  };
export const setpayment = (billing_id, shipping_id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(BillingAction.paymentRequest());
    const data = await axios.post(
      process.env.REACT_APP_API + "/order/create",
      {
        billing_id,
        shipping_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(BillingAction.paymentSuccess(data));
  } catch (error) {
    dispatch(BillingAction.paymentFail(error));
  }
};

export const AddBilling =
  (first_name,
    last_name,
    mobile,
    country,
    city,
    street_address,
    building_name,
    zipcode,
    region,) => async (dispatch) => {
    try {
      dispatch(BillingAction.BillingRequest());
      const { data } = await axios.post(
        process.env.REACT_APP_API + "/billing",
        {
          street_address: street_address,
          region: region,
          city: city,
          country: country,
          first_name: first_name,
          last_name: last_name,
          mobile: mobile,
          building_name: building_name,
          zipcode: zipcode,
        }
      );
      dispatch(BillingAction.BillingSuccess(data.data));
    } catch (error) {
      dispatch(BillingAction.BillingFail(error));
    }
  };