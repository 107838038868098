import { combineReducers } from "redux";
import userSlice from "./Login/Register/LoginReducers";
import BillingSlice from "./Billing/BillingReducers";
import ShippingSlice from "./Shipping/ShippingReducers";
import CareerSlice from "./Career/CareerReducers";
import OrderSlice from "./Order/OrderReducers";
import HomeSlice from "./homepage/HomePageReducer";
import JobSlice from "./Job/JobReducers";
import ApplySlice from "./Apply/ApplyReducers";
import ProductsSlice from "./Products/ProductsReducer";
import BrandSlice from "./Brand/BrandReducers";
import CategorySlice from "./Category/CategoryReducers";
import CardSlice from "./Card/CardReducers";
import PreoSlice from "./PreOrder/PreOrderReducer";
import GiftSlice from "./Gift/GiftReducer";
import FeaturedSlice from "./Featured/FeaturedReducer";
import SettingsSlice from "./settingsRedux";
const RootReducers = combineReducers({
  userReducer: userSlice.reducer,
  BillingReducer: BillingSlice.reducer,
  ShippingReducer: ShippingSlice.reducer,
  careerReducer: CareerSlice.reducer,
  OrderReducer: OrderSlice.reducer,
  HomeReducer: HomeSlice.reducer,
  JobReducer: JobSlice.reducer,
  ApplyReducer: ApplySlice.reducer,
  ProductsReducer: ProductsSlice.reducer,
  BrandReducer: BrandSlice.reducer,
  CategoryReducer: CategorySlice.reducer,
  CardReducer: CardSlice.reducer,
  PreoReducer: PreoSlice.reducer,
  GiftReducer: GiftSlice.reducer,
  FeaturedReducer: FeaturedSlice.reducer,
  SettingsReducer: SettingsSlice.reducer,
});

export default RootReducers;
