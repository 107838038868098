import React, { useState, useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Home/Home";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import WatchesInfo from "./WatchesInfo/WatchesInfo";
import Productspage from "../Pages/ProductsPage/ProductsPage";
import Bestseller from "../Pages/BestSellersPage/BestSellerPage";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";
import FeaturedItems from "./FeaturedItems/FeaturedItems";
import SignIn from "./SignIn/SignIn";
import TrackOrder from "./TrackOrder/TrackOrder";
import TrackOrderStatus from "./TrackOrderStatus/TrackOrderStatus";
import TrackCareers from "./Careers/Careers";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import ContactUs from "./ContactUs/ContactUs";
import Profile from "./Profile/Profile";
import SingleProduct from "./Single Product/SingleProduct";
import ProductDetails from "./ProductDetails/ProductDetails";
import CategoryPreO from "./Category-preO/CategoryPreO";
import PreOrder from "./PreOrder/PreOrder";
import PasswordResetRequest from "./ResetPassword/PasswordResetRequest";
import PasswordResetPage from "./ResetPassword/PasswordResetPage";
import ChangePassword from "./ResetPassword/ChangePassword";
import Storyandnewmodal from "./storyandnewmodal/Storyandnewmodal";
import Favorite from "./Favorite/Favorite";
import FeaturedBrand from "./FeaturedBrands/FeaturedBrand";
import Policy from "./Policy/Policy";
import BillingShipping from "./Cart/Billing&Shipping";
import Gift from "./Gift/Gift";
function PageSwitch() {
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(true);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home") {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location]);
  return (
    <>
      {showHeader && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/watch_info/:id" element={<WatchesInfo />} />
        <Route path="/products" element={<Productspage />} />
        <Route path="/bestsellers" element={<Bestseller />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/featured_items/:id" element={<FeaturedItems />} />
        <Route path="/sign_in" element={<SignIn />} />
        <Route path="/track_order" element={<TrackOrder />} />
        <Route
          path="/track_order_status/:order_number"
          element={<TrackOrderStatus />}
        />
        <Route path="/careers" element={<TrackCareers />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/preo/singleproduct/:id" element={<SingleProduct />} />
        <Route
          path="/product/productdetails/:id"
          element={<ProductDetails />}
        />
        <Route path="/categorypre/:id" element={<CategoryPreO />} />
        <Route path="/preorder" element={<PreOrder />} />
        <Route path="/reset_password" element={<PasswordResetRequest />} />
        <Route path="/reset_page" element={<PasswordResetPage />} />
        <Route path="/change_password" element={<ChangePassword />} />
        <Route path="/Storyandnewmodal/:id" element={<Storyandnewmodal />} />
        <Route path="/Favorite" element={<Favorite />} />
        <Route path="/FeaturedBrand" element={<FeaturedBrand />} />
        <Route path="/Billing&Shipping" element={<BillingShipping />} />
        <Route path="/Gift/:id" element={<Gift />} />

      </Routes>
      <Footer />
    </>
  );
}

export default PageSwitch;
