import React from "react";
import "./ContactUs.css";
import ContactUsImg from "../../assets/Images/ContactUs/contact_us.png";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

function ContactUs() {
  const containerStyle = {
    width: "100%",
    height: "600px",
    marginTop: "4rem",
  };

  const [center, setCenter] = React.useState({
    lat: 33.90163850447034,
    lng: 35.49843267301378,
  });


  const initialMarkerData = [
    {
      id: 1,
      name: "Audemars Piguet Donwtown",
      position: { lat: 33.90163850447034, lng: 35.49843267301378 },
      info: "Downtown - Ahmad Shawki street",
      phone: "03 188 223",
      time: "10am - 6pm",
      country: "Lebanon",
      clicked: false,
    },

    {
      id: 2,
      name: "Montblanc Ashrafieh",
      position: { lat: 33.88892392780756, lng: 35.519897495102846 },
      info: "Abc Ashrafieh",
      phone: "01 322 433",
      time: "10am - 10pm",
      country: "Lebanon",
      clicked: false,
    },

    {
      id: 3,
      name: "Montblanc Verdun",
      position: { lat: 33.88431439433862, lng: 35.48435370674655 },
      info: "Abc Verdun",
      phone: "03 733 023",
      time: "10am - 10pm",
      country: "Lebanon",
      clicked: false,
    },

    {
      id: 4,
      name: "Swatch Ashrafieh",
      position: { lat: 33.88954308291615, lng: 35.51966355767126 },
      info: "Abc Ashrafieh",
      phone: "76 601 482",
      time: "10am - 10pm",
      country: "Lebanon",
      clicked: false,
    },
    {
      id: 5,
      name: "Montblanc Downtown",
      position: { lat: 33.90170274326302, lng: 35.499197096301174 },
      info: "Downtown - Ahmad Shawki street",
      phone: "03 180 910",
      time: "10am - 7pm",
      country: "Lebanon",
      clicked: false,
    },

    {
      id: 6,
      name: "Omega Boutique",
      position: { lat: 33.901096, lng: 35.499162 },
      info: "Ahmad Shawki street",
      phone: "70 040 720",
      time: "10am - 6pm",
      country: "Lebanon",
      clicked: false,
    },
  ];


  const [markerData, setMarkerData] = React.useState(initialMarkerData);

  const handleViewOnGoogleMaps = (placeName, position) => {
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      placeName
    )}&center=${position.lat},${position.lng}`;

    window.open(mapUrl, "_blank");
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA5GBjgheNPPgZgR5KTMqzCV70KqSRXyEM",
  });

  const [map, setMap] = React.useState(null);
  const [position, setPosition] = React.useState({ lat: null, lng: null });

  const onLoad = React.useCallback(function callback(map) {
    // Create a LatLngBounds object with the center point
    const bounds = new window.google.maps.LatLngBounds(center, center);

    // Extend the bounds to include all marker positions
    markerData.forEach((marker) => bounds.extend(marker.position));

    // Fit the map to the bounds
    map.fitBounds(bounds);

    setMap(map);
  }, [center, markerData]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <>
      <div className="contact_us_main_container">
        <div className="header-wrapper">
          <img
            src={ContactUsImg}
            alt="image"
            className="contact_us_img"
            draggable={false}
          />
          <div className="contact_us_text">
            <div className="help_title">WE’re here to help you</div>
          </div>
        </div>
        <div className="contact_us_form">
          <div className="contact_us_content">
            <label className="contact_us_labels spaceing-label">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              className="contact_us_input"
            />
            <label className="contact_us_labels spaceing-label">Last Name</label>
            <input
              type="text"
              placeholder="Last Name"
              className="contact_us_input"
            />
            <div className="contact_us_content_row">
              <div className="contact_us_phone_row">
                <div className="input_width">
                  <label className="contact_us_labels spaceing-label">Phone Number</label>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="contact_us_input customPadding"
                  />
                </div>
              </div>
              <div className="contact_us_email_row">
                <div className="input_width">
                  <label className="contact_us_labels spaceing-label">Email Address</label>
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="contact_us_input"
                  />
                </div>
              </div>
            </div>

            <label className="contact_us_labels spaceing-label">Message</label>
            <textarea
              type="text"
              placeholder="Message"
              className="contact_us_input contact_us_textarea"
            />
            <button className="contact_us_submit_btn">SUBMIT</button>
          </div>
        </div>
        <div className="reach_us_container">
          <label className="reach_us_title">Reach us</label>
          <div className="reach_us_container_row">
            <div className="reach_us_container_col">
              <label className="contact_us_labels">Location</label>
              Jean Tamer Street, Tamer Buildings Sin El Fil, P.O Box: 84 Beirut
              – Lebanon
            </div>
            <div className="reach_us_container_col">
              <label className="contact_us_labels">Headquarter</label>
              Phone: +961 01 513 413 | Fax: +961 01 510 233
            </div>
          </div>

          <div className="reach_us_container_row">
            <div className="reach_us_container_col">
              <label className="contact_us_labels">For General Inquiries</label>
              info@tamer-group.com
            </div>
            <div className="reach_us_container_col">
              <label className="contact_us_labels">After Sale Services</label>
              Phone: +961 81 895 720
            </div>
          </div>

          <div className="reach_us_container_row">
            <div className="reach_us_container_col">
              <label className="contact_us_labels">Hotline</label>
              Phone: +961 70 656 040
            </div>
          </div>
        </div>
        <div className="stores_div_container">
          <div className="title_container">
            <div className="stores_div_title">FIND A STORE</div>
          </div>
          <div className="stores_div">
            {markerData &&
              markerData.map((marker, index) => {
                return (
                  <div key={index} className="store_box">
                    <div className="store_country"
                      onClick={() => {
                        setCenter(marker.position);
                      }}>{marker.name}</div>
                    <div className="store_country"
                      onClick={() => {
                        setCenter(marker.position);
                      }}>{marker.info}</div>
                    <div className="store_data">{marker.phone}</div>
                    <div className="store_data time_div">
                      {marker.time}
                    </div>
                    <div
                      className="view_on_map"
                      onClick={() => {
                        setCenter(marker.position);
                      }}
                    >
                      VIEW ON MAP
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="map_container">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
              className="map-content"
            >

              {markerData &&
                markerData.map((marker, i) => {
                  return (
                    <Marker
                      position={marker.position}
                      onClick={() => {
                        const updatedMarkerData = [...markerData];
                        updatedMarkerData[i].clicked = !updatedMarkerData[i].clicked;
                        setMarkerData(updatedMarkerData);
                        setPosition(marker.position);
                      }}
                      key={i}
                    >
                      {marker.clicked && (
                        <InfoWindow
                          key={'infow-window-' + i}
                          options={{
                            pixelOffset: new window.google.maps.Size(0, -20),
                          }}

                          onCloseClick={() => {
                            const updatedMarkerData = [...markerData];
                            updatedMarkerData[i].clicked = false;
                            setMarkerData(updatedMarkerData);
                          }}

                          position={marker.position}
                        >
                          <div className="map_stores_div">
                            <div className="map_store_box">

                              <div className="map_store_country" onClick={() =>
                                handleViewOnGoogleMaps(
                                  marker.name,
                                  marker.position
                                )
                              }>
                                {marker.name}
                              </div>
                              <div className="map_store_country" onClick={() =>
                                handleViewOnGoogleMaps(
                                  marker.name,
                                  marker.position
                                )
                              }>
                                {marker.info}
                              </div>
                              <div className="map_store_data">
                                {marker.phone}
                              </div>
                              <div className="map_store_data">
                                {marker.time}
                              </div>
                              <div
                                className="view_on_google_map"
                                onClick={() =>
                                  handleViewOnGoogleMaps(
                                    marker.name,
                                    marker.position
                                  )
                                }
                              >
                                View on Google Maps
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                })}
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ContactUs;