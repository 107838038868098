import { React, useState, useEffect } from "react";
import "./SignIn.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { Register, Login } from "../../Redux/Login/Register/LoginActions";
//redux
import { useDispatch, useSelector } from "react-redux";
import PopUp from "../../Components/PopUp/PopUp";
import { GoogleLogin } from "react-google-login";
import axios from "axios";
import AppleLogin from "react-apple-login";
import { FiEye, FiEyeOff } from 'react-icons/fi';
function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [issignup, setissignup] = useState(true);
  const [password, setpassword] = useState("");
  const [email, setemail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [rememberme, setrememberme] = useState(false);
  const { user, loading, error } = useSelector((Store) => Store.userReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationSubmit, setNotificationSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (error != "") {
      setErrorMessage(error?.response?.data?.message);
    }
  }, [error]);
  const handleRegister = (event) => {
    event.preventDefault();
    dispatch(Register(email, firstName, lastName, password));
  };
  const handlelogin = () => {
    setErrorMessage("");
    setNotificationSubmit(true);
    dispatch(Login(email, password, rememberme, navigate));
  };

  const handleGoogleLogin = () => {
    window.location.href = process.env.REACT_APP_API + "/auth/google";
  };
  const handleAppleLogin = () => {
    window.location.href = process.env.REACT_APP_API + "/login";
  };

  return (
    <>
      {issignup ? (
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className="sign_in_container_main">
              <div className="sign_in_container">
                <div className="sign_in_title">CREATE ACCOUNT</div>
                {user.token ? (
                  <>
                    <div className="verify_text">
                      Please verify your Account by clicking on the link sent to
                      your email
                    </div>
                  </>
                ) : (
                  <form onSubmit={handleRegister}>
                    <div className="input_label">First Name *</div>
                    <input
                      required
                      type="text"
                      placeholder="Enter your first name"
                      className="email_input"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                    <div className="input_label">Last Name *</div>
                    <input
                      required
                      type="text"
                      placeholder="Enter your last name"
                      className="email_input"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                    <div className="input_label">
                      USERNAME OR EMAIL ADDRESS *
                    </div>
                    <input
                      required
                      type="text"
                      placeholder="Enter your email"
                      className="email_input"
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                    />
                    <div className="input_label">PASSWORD *</div>
                    <input
                      required
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      className="password_input"
                      onChange={(e) => {
                        setpassword(e.target.value);
                      }}
                    />
                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FiEye /> : <FiEyeOff />}
                    </span>
                    <button className="login_btn" type="submit">
                      Register
                    </button>{" "}
                  </form>
                )}

                {error && (
                  <div className="text_cont_err">
                    {error.response?.data?.message}
                  </div>
                )}

                <div className="text_cont">
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our privacy policy.
                </div>
                <div className="divofsignwith">
                  <button
                    onClick={handleGoogleLogin}
                    className="google-login-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.6 10.2273C19.6 9.51819 19.5364 8.83637 19.4182 8.18182H10V12.05H15.3818C15.15 13.3 14.4455 14.3591 13.3864 15.0682V17.5773H16.6182C18.5091 15.8364 19.6 13.2727 19.6 10.2273Z"
                        fill="#4285F4"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 20C12.7 20 14.9636 19.1045 16.6182 17.5772L13.3864 15.0681C12.4909 15.6681 11.3455 16.0227 10 16.0227C7.39546 16.0227 5.19091 14.2636 4.40455 11.9H1.06364V14.4909C2.7091 17.7591 6.09091 20 10 20Z"
                        fill="#34A853"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.40455 11.8999C4.20455 11.2999 4.09091 10.659 4.09091 9.99994C4.09091 9.34085 4.20455 8.69994 4.40455 8.09994V5.50903H1.06364C0.386364 6.85903 0 8.38631 0 9.99994C0 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.8999Z"
                        fill="#FBBC05"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 3.97727C11.4682 3.97727 12.7864 4.48182 13.8227 5.47273L16.6909 2.60455C14.9591 0.990909 12.6955 0 10 0C6.09091 0 2.7091 2.24091 1.06364 5.50909L4.40455 8.1C5.19091 5.73636 7.39546 3.97727 10 3.97727Z"
                        fill="#EA4335"
                      />
                    </svg>
                    Sign with Google
                  </button>
                  <button
                    onClick={handleAppleLogin}
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      border: "1px solid black",
                      fontFamily: "none",
                      lineHeight: "25px",
                      fontSize: "16px",
                      width: "75%",
                    }}
                  >
                    <i className="fa-brands fa-apple px-2 "></i>
                    Continue with Apple
                  </button>
                </div>
                <div className="signup_div" onClick={() => setissignup(false)}>
                  Have an account? Sign in
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="sign_in_container_main">
          {loading ? (
            <Loader />
          ) : (
            <div className="sign_in_container">
              <div className="sign_in_title">Sign In</div>
              <div className="input_label">USERNAME OR EMAIL ADDRESS *</div>
              <input
                type="text"
                placeholder="Enter your email"
                className="email_input"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />

              <div className="password-input-container">
                <div className="input_label">PASSWORD *</div>

                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password"
                  className="password_input"
                  value={password}
                  onChange={(e) => {
                    setpassword(e.target.value);
                  }}
                />
                <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FiEye /> : <FiEyeOff />}
                </span>
              </div>

              <div className="remember_div">
                <div>
                  <input
                    type="checkbox"
                    name="remember_me"
                    id="remember_me"
                    className="remember_me_checkbox"
                    value={rememberme}
                    onChange={(e) => {
                      setrememberme(e.target.checked);
                    }}
                  />
                  <label htmlFor="remember_me" className="label_check">
                    Remember me
                  </label>
                </div>
                <div
                  className="forgot_div"
                  onClick={() => navigate("/reset_password")}
                >
                  Forgot your password?
                </div>
              </div>
              <button className="login_btn" onClick={handlelogin}>
                login
              </button>
              <div className="create_acc" onClick={() => setissignup(true)}>
                Create Account
              </div>
            </div>
          )}
        </div>
      )}

      {notificationSubmit && !loading && errorMessage !== "" && (
        <PopUp
          setOpen={setNotificationSubmit}
          body={<div className="popup_title">{errorMessage}</div>}
        />
      )}
    </>
  );
}

export default SignIn;
