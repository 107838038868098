import React, { useState, useEffect } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setpayment } from "./../../Redux/Billing/BillingActions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  getMyCards,
  editMyCard,
  RemoveProductFromMyCard,
  deleteCart,
} from "./../../Redux/Card/CardActions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
import isAuth from "./../../Utils/isAuth";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import Loader from "../../Components/Loader/Loader";
import TrashIcon from "./TrashIcon";

function Cart() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    cards,
    editedCard,
    editedError,
    editedLoading,
    deletedCard,
    deletedError,
    deletedLoading,
    loading,
  } = useSelector((state) => state.CardReducer);

  const [items, setItems] = useState([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [editCardAction, setEditCardAction] = useState(false);
  const [deleteCardAction, setDeleteCardAction] = useState(false);
  const [CountinfProblem, setCountinfProblem] = useState(0);
  console.log("Items : ",items);
  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/sign_in");
  }, [dispatch, navigate]);

  useEffect(() => {
    let actionDispatched = false;
    if (editCardAction && !editedLoading) {
      if (!actionDispatched && editedCard !== [] && editedError === null) {
        NotificationManager.success("Cart edited successfully", "Success");
        actionDispatched = true;
      } else if (editedError) {
        NotificationManager.error('editedError');
      }
      setEditCardAction(false);
    }
  }, [editedLoading, editedCard, editedError, editCardAction]);

  useEffect(() => {
    let actionDispatched = false;
    if (deleteCardAction && !deletedLoading) {
      if (!actionDispatched && deletedCard !== [] && deletedError === null) {
        NotificationManager.success("Cart deleted successfully", "Success");
        actionDispatched = true;
      } else if (deletedError) {
        NotificationManager.error(deletedError);
      }
      setDeleteCardAction(false);
    }
  }, [deletedLoading, deletedCard, deletedError, deleteCardAction]);

  useEffect(() => {
    dispatch(getMyCards());
  }, [dispatch]);

  useEffect(() => {
    if (cards?.data != []) {
      setItems(cards?.data);
    }
  }, [cards]);


  const handleQuantityChange = (id, newQuantity) => {
    dispatch(editMyCard(id, newQuantity));

    const updatedItems = items?.map((item) => {
      if (item.id === id) {
        if (newQuantity <= 0) {
          setShowRemoveConfirmation(true); // set state to show confirmation button
          return { ...item, product_quantity: 0 }; // set quantity to 0
        } else {
          return { ...item, product_quantity: newQuantity }; // update quantity
        }
      } else {
        return item;
      }
    });

    setItems(updatedItems);
  };
  const calculateSubtotal = () => {
    return items?.reduce((total, item) => {
      return total + item?.product?.price * item?.product_quantity;
    }, 0);
  };
  const calculateTotal = () => {
    const subtotal = items?.reduce((total, item) => {
      return total + item?.product?.price * item?.product_quantity;
    }, 0);
    return subtotal + 5;
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <NotificationContainer />
          <div className="my_cart">
            <div className="inside">
              <div className="homecontainer">
                <div className="home">
                  {" "}
                  HOME &nbsp; &nbsp;/ &nbsp; &nbsp;
                  <span className="cartname"> CART</span>
                </div>
                <div className="myCart">MY CART</div>
              </div>
            </div>
          </div>
          <div className="Main_cart_div">
            <div className="cart">
              <div className="navbar-end">
                <a
                  className="navbar-item active"
                  onClick={() => navigate("/cart")}
                >
                  CART ITEMS
                </a>
                &nbsp;&nbsp;
                <a href="/Billing&Shipping" className="navbar-item">BILLING & SHIPPING</a>
              </div>
              {items?.length === 0 ? (
                <div className="empty_cart">
                  <div>Your cart is empty</div>
                  <button
                    className="filters_more_button11"
                    onClick={() => navigate("/products")}
                  >
                    Go to Shop
                  </button>
                </div>
              ) : (
                <div className="cart_products_allcont">
                  {/* // dispatch(deleteCart()) */}
                  {items?.length > 0 && (<div className="trash">
                    <div onClick={() => {
                      dispatch(deleteCart());
                    }}>
                      <TrashIcon/>
                      </div>
                    </div>)}
                  <div>
                    {" "}
                    {items?.map((item, index) => (
                      <div key={index} className="cart_product_container">
                        <div className="all_container">
                          <div className="cart_product">
                            <div className="cart_product_image">
                              <img
                                src={item?.product?.img_url}
                                alt={item?.product?.name}
                                className="cart_img"
                                onClick={() =>
                                  navigate(
                                    "/product/productdetails/" +
                                    item?.product_id
                                  )
                                }
                              />
                            </div>
                            <div className="price-container">
                            <div className="cart_price">
                              <div className="cart_price_text small-text">
                              Unit price
                              </div>
                              <div className="cart_price_text">
                                ${item?.product?.price}
                              </div>
                            </div>
                            </div>
                            <div className="cart_product_description">
                              {item?.product?.description === "NULL" ? <p></p> : <>{item?.product?.description}</>}
                            </div>
                            {(item?.product?.OmegaAttribute?.CaseDiameter ||
                              item?.product?.SwatchAttribute?.CaseWidth) && (
                                <div className="cart_product_description">
                                  {item?.product?.OmegaAttribute
                                    ? item?.product?.OmegaAttribute?.CaseDiameter
                                    : item?.product?.SwatchAttribute // Fix the typo here
                                      ? item?.product?.SwatchAttribute?.CaseWidth
                                      : ""}
                                </div>
                              )}
                          </div>
                          <div className="cart-info">
                            <div className="cart_quantity">
                              {showRemoveConfirmation &&
                                item?.product_quantity === 0 ? (
                                <>
                                  <div>
                                    <p>Remove this item from your cart?</p>
                                    <button
                                      onClick={() => {
                                        setShowRemoveConfirmation(false);
                                        setDeleteCardAction(true);
                                        dispatch(
                                          RemoveProductFromMyCard(item.id)
                                        );
                                        dispatch(getMyCards());
                                      }}
                                      className="remove_button"
                                    >
                                      Yes, Remove
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <div className="quantity_div_Container">
                                  <div
                                    className="quantity_div_remove"
                                    onClick={() => {
                                      handleQuantityChange(
                                        item.id,
                                        item?.product_quantity - 1
                                      );
                                      setEditCardAction(true);
                                    }}
                                  >
                                    -
                                  </div>
                                  <div className="quantity_div">
                                    {item?.product_quantity}
                                  </div>
                                  <button
                                    disabled={item?.product_quantity >= item?.product.quantity_limit}
                                    className="quantity_div_remove"
                                    onClick={() => {
                                      setEditCardAction(true);
                                      if (!editedError) {
                                        handleQuantityChange(
                                          item.id,
                                          item?.product_quantity + 1
                                        )
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                  <div className="price-container">
                            <div className="cart_price" style={{marginLeft:"2rem"}}>
                            <div className="cart_price_text small-text">
                              Total price
                              </div>
                              <div className="cart_price_text">
                                ${item?.product?.price * item?.product_quantity}
                              </div>
                            </div>
                            </div>
                                </div>
                              )}
                            </div>

                          </div>
                        </div>
                        <div className="Section4_underline" />
                      </div>
                    ))}
                  </div>
                   <div className="cart_total">
                    <div className="cart_total_text">CART TOTAL</div>
                    <div className="cart_subtotals_text">
                      <div className="cart_subtotals_text1">Subtotal</div>
                      <div className="cart_subtotals_text2">
                        ${calculateSubtotal()?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </div>
                    </div>
                    <div className="cart_underline" />
                    <div className="cart_subtotals_text">
                      <div className="cart_subtotals_text1">SHIPPING</div>
                      <div className="cart_subtotals_text3">
                        Delivery Charge: $5.00
                      </div>
                      <div className="cart_subtotals_text4">
                        Shipping options will be updated during checkout.
                      </div>
                    </div>
                    <div className="cart_underline" />
                    <div className="cart_subtotals_text">
                      <div className="cart_subtotals_text1">Total</div>
                      <div className="cart_subtotals_text2">
                        ${calculateTotal().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </div>
                    </div>
                    <div className="cart_button_text">
                      <button
                        className="cart_button"
                        onClick={() => navigate("/Billing&Shipping")}
                      >
                        PROCEED TO CHECKOUT
                      </button>
                    </div>
                  </div>

                </div>
              )}
            </div>
          </div>
          {cards?.data?.length > 0 && (<>
            <div className="heading_title_2">
              Recommendation
            </div>
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={2000}
              infiniteLoop={true}
            >
              {cards?.products?.map((product, index) => (
                <WatchesViewCard
                  key={index} // Add a unique key prop to each child element
                  link={product.id}
                  image={product.img_url}
                  brand={product.title}
                  price={product.price}
                />
              ))}
              </Carousel></>)}
        </>
      )}
    </div>
  );
}

export default Cart;