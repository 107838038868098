import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shippings: [],
  shipping:[],
  loading: false,
  error: null,
};

const ShippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    ShippingRequest(state, action) {
      state.loading = true;
    },
    ShippingSuccess(state, action) {
      state.loading = false;
      state.shippings = action.payload;
    },
    ShippingFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    ShippingByIdRequest(state, action) {
      state.loading = true;
    },
    ShippingByIdSuccess(state, action) {
      state.loading = false;
      state.shipping = action.payload;
    },
    ShippingByIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

  },
});

export const ShippingAction = ShippingSlice.actions;
export default ShippingSlice;
