import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gift: [],
  loading1: false,
  error: null,
};

const GiftSlice = createSlice({
  name: "gift",
  initialState,
  reducers: {
    GiftRequest(state, action) {
      state.loading1 = true;
      state.gift = [];
      state.error = [];
    },
    GiftSuccess(state, action) {
      state.loading1 = false;
      state.error = [];

      state.gift = action.payload;
    },
    GiftFail(state, action) {
      state.loading1 = false;
      state.error = action.payload;
      state.gift = [];
    },
  },
});

export const GiftAction = GiftSlice.actions;
export default GiftSlice;
