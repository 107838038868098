import React, { useState, useEffect } from "react";
import "../FeaturedItems/FeaturedItems.css";
import "./FeaturedBrand.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import { GetBrand } from "../../Redux/Brand/BrandActions";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import Carousel from "react-multi-carousel";
import Loader from "../../Components/Loader/Loader";
import { GetFeatured } from "../../Redux/Featured/FeaturedActions";

function FeaturedBrand() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const brand_id = query.get("brand_id") !== null ? query.get("brand_id") : "";
  const { brand, loading } = useSelector((state) => state.BrandReducer);
  const { featured } = useSelector((state) => state.FeaturedReducer);

  useEffect(() => {
    dispatch(GetBrand(brand_id));
    dispatch(GetFeatured())

  }, [brand_id, dispatch]);
  const [onepro, setonepro] = useState([]);
  useEffect(() => {
    setonepro(brand?.data?.brand);
  }, [brand, featured]);

  const images = [{ url: 'https://www.1zoom.me/prev/323/322504.jpg' },
  { url: 'https://www.1zoom.me/prev/323/322504.jpg' }]

  // Assuming you have declared 'const [images, setImages] = useState([]);' elsewhere


  // if (featured) {
  //   featured[0]?.featured_brandImages.forEach((item) => {
  //     images.push({ url: item.image });
  //   });
  // }


  const flattenedImages = brand?.data?.relatedProducts.reduce((acc, product) => {
    return acc.concat(product.ProductImages);
  }, []);
  const [mainpic, setMainpic] = useState();
  useEffect(() => {
    setMainpic(onepro?.img_url);
  }, [dispatch, onepro]);

  if (loading) { return <Loader /> }

  return (
    <>
      <div className="featured_items_main_container">
        <div className="featured_items_container_1">
          {/* <div className="featured_items_container_info1">
            <div className="featured_items_container_info">
              <img
                src={
                  onepro?.img_url
                }
                alt="brandimage"
                style={{ width: "13rem" }}
              />
              <div className="threematerial2">





              </div>

              <div className="featured_item_details_title">{onepro?.title}</div>
              <div className="swatch_details">{onepro?.description}</div>
              <div className="buttons_div"></div>
            </div>
          </div> */}
          <div className="Header">
            <img
              src={onepro?.img_url}
              alt="Featured_Item"
              className="Featured_Item"
            />
            <div className="swatch_details">{onepro?.description}</div>
          </div>
        </div>
        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider black-divider"></div>
        </div>
        <div className="slider-container">
          <SimpleImageSlider
            width={window.innerWidth > 768 ? 600 : 300}
            height={window.innerWidth > 768 ? 300 : 150}
            autoPlay={true}
            images={images}
            showBullets={false}
            showNavs={true}
            className="slider-content"
          />
        </div>
        <div className="descrition-section">
          <div className="descrition-center">
            <p>
              {featured && featured[0]?.first_description_for_page}
            </p>
          </div>
        </div>
        <div className="images-section">
          {featured && <>
            <img src={"https://alfa.tamer-group.com//featuredBrand/" + featured[0]?.img_url_firstsection} alt="ImageNotFound" />
            <img src={"https://alfa.tamer-group.com//featuredBrand/" + featured[0]?.img_url_secondsection} alt="ImageNotFound" />
          </>}
        </div>
        <div className="about-section">
          <div className="center-container">
            <h3>About {brand && brand?.data?.brand?.name}</h3>
            <p>
              {featured && featured[0]?.second_description_for_page}
            </p>
          </div>
        </div>
        <div className="heading_titles">
          <div className="heading_title_2">related products</div>
        </div>
        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider"></div>
        </div>
        <div className="watching_info_display">
          {brand?.data?.relatedProducts?.map((product, index) => (
            <WatchesViewCard
              link={product.id}
              image={product.img_url} // Assuming the image is part of the product object
              brand={product.title}
              key={index}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default FeaturedBrand;
