import axios from "axios";
import { BrandAction } from "./BrandReducers";

export const GetBrands = () => async (dispatch) => {
  try {
    dispatch(BrandAction.BrandRequest());
    const apiUrl = process.env.REACT_APP_API + "/brand/";
    console.log("API URL for fetching brands:", apiUrl); // Log API URL
    const { data } = await axios.get(apiUrl);
    console.log("Data received for brands:", data); // Log received data
    dispatch(BrandAction.BrandSuccess(data.data));
  } catch (error) {
    console.error("Error fetching brands:", error); // Log error
    dispatch(BrandAction.BrandFail(error));
  }
};

export const GetBrand = (id) => async (dispatch) => {
  try {
    dispatch(BrandAction.OneBrandRequest());
    const apiUrl = process.env.REACT_APP_API + "/brand/getOne/" + id;
    console.log("API URL for fetching brand with id", id, ":", apiUrl); // Log API URL with ID
    const { data } = await axios.get(apiUrl);
    console.log("Data received for brand with id", id, ":", data); // Log received data
    dispatch(BrandAction.OneBrandSuccess(data));
  } catch (error) {
    console.error("Error fetching brand with id", id, ":", error); // Log error with ID
    dispatch(BrandAction.OneBrandFail(error));
  }
};
