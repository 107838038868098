import React, { useState, useEffect } from "react";
import "./Cart.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setpayment } from "./../../Redux/Billing/BillingActions";
import {
  getMyCards,
  editMyCard,
  RemoveProductFromMyCard,
} from "./../../Redux/Card/CardActions";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
import isAuth from "./../../Utils/isAuth";

import BillingShippings from "../../Components/Billing&Shipping/BillingShipping";

function BillingShipping() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    cards,
    editedCard,
    editedError,
    editedLoading,
    deletedCard,
    deletedError,
    deletedLoading,
  } = useSelector((state) => state.CardReducer);

  const [items, setItems] = useState([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [editCardAction, setEditCardAction] = useState(false);
  const [deleteCardAction, setDeleteCardAction] = useState(false);



  const [billingID, setBillingID] = useState("");
  const [shippingID, setShippingID] = useState("");

  const handleBillingChange = (billingID) => {
    setBillingID(billingID);
  };

  const handleShippingChange = (shippingID) => {
    setShippingID(shippingID);
  };

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/sign_in");
  }, [dispatch, navigate]);

  const { payment } = useSelector((state) => state.BillingReducer);

  const handlePayment = (billingID, shippingID) => {
    dispatch(setpayment(billingID, shippingID));
  };


  useEffect(() => {
    if (payment && payment.data) {
      window.open(payment.data, "_blank"); // Use window.open to open the link in a new tab
    }
  }, [payment, payment?.data]);




  const handleClick = () => {
    if (!billingID || !shippingID) {
      NotificationManager.error(
        " Both Billing and Shipping Info are Required "
      );
    } else {
      // Handle payment logic here
      handlePayment(billingID, shippingID);
      setBillingID("");
      setShippingID("");
    }
  };

  useEffect(() => {
    dispatch(getMyCards());
  }, [dispatch]);

  useEffect(() => {
    if (cards?.data != []) {
      setItems(cards?.data);
    }
  }, [cards]);

  const calculateSubtotal = () => {
    return items?.reduce((total, item) => {
      return total + item?.product?.price * item?.product_quantity;
    }, 0);
  };
  const calculateTotal = () => {
    const subtotal = items?.reduce((total, item) => {
      return total + item?.product?.price * item?.product_quantity;
    }, 0);
    return subtotal + 5;
  };

  return (
    <div>
      <NotificationContainer />
      <div className="my_cart">
        <div className="inside">
          <div className="homecontainer">
            <div className="home">
              {" "}
              HOME &nbsp; &nbsp;/ &nbsp; &nbsp;
              <span className="cartname"> CART</span>
            </div>
            <div className="myCart">MY CART</div>
          </div>
        </div>
      </div>
      <div className="Main_cart_div">
        <div className="navbar-end">
          <a className="navbar-item" onClick={() => navigate("/cart")}>
            CART ITEMS
          </a>
          &nbsp;&nbsp;
          <a className="navbar-item active">BILLING & SHIPPING</a>
        </div>
      </div>
      <div className="billingandshipping">
        <BillingShippings
          onBillingChange={handleBillingChange}
          onShippingChange={handleShippingChange}
        />

        <div className="cart_total">
          <div className="cart_total_text">CART TOTAL</div>
          <div className="cart_subtotals_text">
            <div className="cart_subtotals_text1">Subtotal</div>
            <div className="cart_subtotals_text2">${calculateSubtotal()?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
          </div>
          <div className="cart_underline" />
          <div className="cart_subtotals_text">
            <div className="cart_subtotals_text1">SHIPPING</div>
            <div className="cart_subtotals_text3">Delivery Charge: $5.00</div>
          </div>
          <div className="cart_underline" />
          <div className="cart_subtotals_text">
            <div className="cart_subtotals_text1">Total</div>
            <div className="cart_subtotals_text2">
              ${calculateTotal().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </div>
          </div>
          <div className="cart_button_text">
            <button className="cart_button" onClick={handleClick}>
              PROCEED TO CONFIRMATION
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}

export default BillingShipping;
