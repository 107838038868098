import React, { useRef, useEffect, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Storyandnewmodal from "../storyandnewmodal/Storyandnewmodal";

//images import

import image8 from "../../assets/Images/Home/IMAGE (10).png";
import image9 from "../../assets/Images/Home/IMAGE (11).png";
import image10 from "../../assets/Images/Home/IMAGE (12).png";
import image11 from "../../assets/Images/Home/IMAGE (13).png";
import image12 from "../../assets/Images/Home/IMAGE (14).png";
import { BsInstagram } from "react-icons/bs";
import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
//components import
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import NewsStoriesCard from "../../Components/News&StoriesCard/NewsStoriesCard";
import { getVideo } from "./../../Redux/homepage/HomePageAction";
import { getPartner } from "./../../Redux/homepage/HomePageAction";
import { getStory, getbrand } from "./../../Redux/homepage/HomePageAction";
import { GetBrands } from "../../Redux/Brand/BrandActions";
import PopUp from "../../Components/PopUp/PopUp";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from "react-router-dom";
import moment from "moment";

function Home() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");
  const [show, setShow] = useState(false);
  //toggle
  const toggle = (description, title, image) => {
    setShow(!show);
    setdescription(description);
    settitle(title);
    setimage(image);
  };

  const [description, setdescription] = useState("");
  const [title, settitle] = useState("");
  const [image, setimage] = useState();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    dispatch(getVideo());
    dispatch(getPartner());
    dispatch(getStory());
    dispatch(getbrand());
    dispatch(GetBrands());
  }, [dispatch]);
  const { homevideo, ourPartner, story, brandfeature } = useSelector(
    (state) => state.HomeReducer
  );

  const { brands } = useSelector((Store) => Store.BrandReducer);

  const [rec1, setRec1] = useState([]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("token", token);
      // console.log("Token:", token);
    } else {

    }
  }, []);


  //useeffect to setsource
  useEffect(() => {
    setSource(homevideo?.data?.data?.video_url);
  }, [homevideo]);

  const [rec, setRec] = useState([]);
  useEffect(() => {
    if (brandfeature && brandfeature?.data && brandfeature?.data[0]) {
      setRec(brandfeature?.data[0]);
    }
  }, [brandfeature]);

  const mainHomeRef = useRef(null);
  const [isMainHomeEnded, setIsMainHomeEnded] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const mainHomePos = mainHomeRef?.current?.offsetTop;
  //     const mainHomeHeight = mainHomeRef?.current?.offsetHeight;
  //     const scrollPos = window.pageYOffset + window?.innerHeight;

  //     if (window.innerWidth <= 551) {
  //       if (scrollPos >= mainHomePos + mainHomeHeight / 5) {
  //         setIsMainHomeEnded(true);
  //       } else {
  //         setIsMainHomeEnded(false);
  //       }
  //       return
  //     }


  //     if (window.innerWidth <= 1051) {
  //       if (scrollPos >= mainHomePos + mainHomeHeight / 2) {
  //         setIsMainHomeEnded(true);
  //       } else {
  //         setIsMainHomeEnded(false);
  //       }
  //       return
  //     }

  //     if (scrollPos >= mainHomePos + mainHomeHeight - 100) {
  //       setIsMainHomeEnded(true);
  //     } else {
  //       setIsMainHomeEnded(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <Header isMainHomeEnded={isMainHomeEnded} />

      <div className="Main_Home">
        <div className="Home_Video">
          <video autoPlay
            muted
            loop
            className="home_vid"
            width="100%"
            src={source}
            playsInline
            style={{ pointerEvents: "none" }}
            type="video/mp4"
          >
          </video>
        </div>
        <div className="Home_vid_text">
          <div className="Home_vid_text1">WONDERING WHAT TO CHOOSE?</div>

          <div className="Home_vid_text2">
            WE GOT YOU COVERED WITH SOME CHOICES
          </div>
          <div className="Home_vid_button_section">
            <button
              className="Home_vid_button"
              onClick={() => navigate("/products")}
            >
              DISCOVER MORE
            </button>
          </div>
        </div>
      </div>
      <div ref={mainHomeRef}>
        <Section1 />
      </div>
      <Section2 />
      <div className="Home_Section3" style={{ backgroundColor: rec?.color }}>
        <div className="Home_Section3_text">
          <div className="text_cont1">
            <div className="Home_Section3_text1">{rec?.title1}</div>
          </div>
          <div className="text_cont2">
            <div className="Home_Section3_text2">{rec?.title2}</div>
          </div>
          <div className="text_cont3">
            <div className="Home_Section3_text3">{rec?.description}</div>
          </div>

          <div
            className="Home_Section3_button_section"
            onClick={() => navigate(`/FeaturedBrand?brand_id=${rec.brand_id}`)}
          >
            <button className="Home_vid_button-theird-section">DISCOVER MORE</button>
          </div>
        </div>
        <div className="Home_Section3_img">
          <img
            src={rec?.img_url}
            alt="section3img"
            className="section3_img"
            draggable={false}
          />
        </div>
      </div>

      <div className="Home_Section4">
        <div className="Section2_Header">
          <div className="Section2_Header2">NEWS & STORIES</div>
          <div className="Section2_Headeer_underline" />
        </div>
        {show && (
          //popmodal
          <PopUp
            body={
              <Storyandnewmodal
                description={description}
                title={title}
                image={image}
              />
            }
            setOpen={toggle}
          ></PopUp>
        )}

        <div className="postWrapper">

          {story?.newsAndStories?.length > 0 &&
            story?.newsAndStories?.map((story, index) => (
              <div className="Home_Section4_cards" key={index}>
                <NewsStoriesCard
                  title={story?.title}
                  image={story?.image}
                  date={moment(story?.createdAt).format("YYYY-MM-DD")}
                  description={story?.description}
                  id={story?.id}
                />
              </div>
            ))}
        </div>


      </div>
      <div className="Home_Section5">
        <div className="Section2_Header">
          <div className="Section2_Header2">OUR PARTNERS</div>
          <div className="Section2_Headeer_underline" />
        </div>

        {/* {brands[0] && (
          <Carousel
            responsive={responsive}
            autoPlay={true}
            centerMode={true}
            autoPlaySpeed={1000}
            infiniteLoop={true}
            containerClass="carousel-container1"
          >
            {brands.map((partner, index) => (
              <img
                key={index}
                src={partner?.img_url}
                alt={`image${index}`}
                className="Home_Section5_img"
              />
            ))}
             
          </Carousel>
        )} */}

        <div className="slider">
          <div className="slide-track">
            {brands.map((partner, index) => (
              <div className="slide" key={index}>
                <img key={index} src={partner?.img_url} alt={`image${index}`} height="60" width="250" />

              </div>
            ))}

          </div>
        </div>

      </div>
      <div className="Home_Section6">
        <div className="Section6_imgs_cont">
          <img src={image8} alt="image8" className="Home_Section6_img" />

          <img src={image9} alt="image8" className="Home_Section6_img" />

          <img src={image10} alt="image8" className="Home_Section6_img" />

          <img src={image11} alt="image8" className="Home_Section6_img" />

          <img src={image12} alt="image8" className="Home_Section6_img" />
          <div className="insta_cont">
            <div className="insta_icon">
              <BsInstagram size={30} />
            </div>
            <div className="insta_text">FOLLOW US ON INSTAGRAM</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
