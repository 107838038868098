import { React, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./MobileHeader.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import logo2 from "../../assets/Images/Header/Logo2.png";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsCart2 } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { Logout } from "../../Redux/Login/Register/LoginActions";
import PopUp from "../PopUp/PopUp";
import SendQuery from "../../Pages/SendQuery/SendQuery";
import isAuth from "../../Utils/isAuth";

function MobileHeader(props) {
  const { favorite } = useSelector((state) => state.HomeReducer);
  const { cards } = useSelector((state) => state.CardReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;
  const instagramLink = "https://www.instagram.com/tamer.freres/?hl=en";
  const facebookLink = "https://www.facebook.com/TamerFreres/";
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get("search");
    setSelectedFilter(filterParam || "");
  }, [location.search]);
  const [selectFilter, setSelectedFilter] = useState("");
  const handleSearch = () => {
    navigate(`/products?search=${selectFilter}`); // Navigate to the product page
    props.setisshown(false);
  };


  const logoutuser = () => {
    dispatch(Logout(navigate));
  };
  const [open, setOpen] = useState(false);
  const handleclose = () => {
    setOpen(false);
  };


  return (
    <div className="Main_mobile_header">
      <div className="mobile_header_cont">


        <div className="mobile_header_close">
          <AiOutlineCloseCircle
            size={40}
            className="close_butt"
            onClick={() => props.setisshown(false)}
          />
          {isAuth() ? <div className="icon-mobile">
            <div
              className="header-icons-burger"
              onClick={() => {
                navigate("/Favorite")
                props.setisshown(false)
              }}
            >
              <AiOutlineHeart className="header-icons-burger" size={30} />
              {favorite?.data?.data.length > 0 && (
                <div className="favorite-count">
                  {favorite?.data?.data?.length}
                </div>
              )}
            </div>
            &nbsp;
            &nbsp;
            <div
              className="header-icons-burger"
              onClick={() => {
                navigate("/cart")
                props.setisshown(false)
              }}
            >
              <BsCart2 className="header-icons-burger" size={30} />
              {cards?.data?.length > 0 && (
                <div className="favorite-count">
                  {cards?.data?.length}
                </div>
              )}
            </div>
          </div> : <></>}
        </div>
        <div className="mobile_header_image">
          <img src={logo2} alt="logo" className="mobile_header_logo" />
        </div>
      </div>

      <div className="mobile_header_icons">
        <div className="header_icons_container1">
          <div className="header-icons-burger">
            <a
              href={facebookLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiFacebookCircleLine className="header-icons-burger" size={30} />
            </a>
          </div>
          <div className="header-icons-burger">
            <a
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <BsInstagram className="header-icons-burger" size={25}

              />
            </a>{" "}
          </div>

          <a
            href="https://api.whatsapp.com/send?phone=96170656040"
            className="wp_a"
            target="_blank"
          >
            <div className="header_wp_cont">
              <div className="header-icons-burger">
                <IoLogoWhatsapp className="header-icons-burger" size={25} />
              </div>
            </div>
          </a>
        </div>
        <div className="mobile_header_links">
          <div className="search-container">
            <div className="search-bar-burger">
              <input
                style={{ outline: 'none', }}
                type="text"
                placeholder="Search"
                className="search-input2"
                value={selectFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              />

              <div className="">
                <FiSearch
                  className="header-icons-burger"
                  size={25}
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleSearch();
                  }}
                />
              </div>
            </div>
          </div>



          {!isAuth() && (<div
            className="header_wp_cont"
            onClick={() => navigate("/sign_in")}
          >
            <div style={{ color: "black", paddingTop: "1rem", display: "block" }} className="header_wp_text"> Sign In or Register </div>
          </div>)}

          <div
            className="mobile_header_link"
            onClick={() => {
              navigate("/");
              props.setisshown(false);
            }}
          >
            Home
          </div>
          <div
            className="mobile_header_link"
            onClick={() => {
              navigate("/careers");
              props.setisshown(false);
            }}
          >
            Career
          </div>
          {isAuth() && (<div
            className="mobile_header_link"
            onClick={() => {
              navigate("/profile");
              props.setisshown(false);
            }}
          >
            Profile
          </div>)}

          <div
            className="mobile_header_link"
            onClick={() => {
              navigate("/preorder");
              props.setisshown(false);
            }}
          >
            Pre-O
          </div>
          {/* <div className="mobile_header_link" 
            onClick={() => {
              navigate("/after_sales");
              props.setisshown(false);
            }}
          >After Sales</div> */}
          <div
            className="mobile_header_link"
            onClick={() => {
              navigate("/contact_us");
              props.setisshown(false);
            }}
          >
            Contact Us
          </div>

          <div
            className="mobile_header_link"
            onClick={
              location.pathname === "/" ? () => {
                setOpen(true);
                props.setisshown(false);
                props.showSale()
              }
                : () => {
                  setOpen(true);
                }
            }
          >
            After Sale
          </div>
          {isAuth() && (
            <div
              className="mobile_header_link"
              onClick={() => {
                logoutuser();
                props.setisshown(false);
              }}>
              Logout
            </div>)}


        </div>
      </div>

      {open && (
        <PopUp
          setOpen={setOpen}
          body={<SendQuery isclosed={handleclose} />}
        ></PopUp>
      )}
    </div>
  );
}

export default MobileHeader;
