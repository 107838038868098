import React, { useState, useEffect } from "react";
import "./FeaturedItems.css";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { GoLocation, GoGift } from "react-icons/go";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import watchimg from "../../assets/Images/Home/uwt_114_copy.jpg";
import { useNavigate } from "react-router-dom";
import { addToCard } from "./../../Redux/Card/CardActions";
import { useDispatch, useSelector } from "react-redux";
import { getfeatureproducg } from "./../../Redux/Products/ProductsAction";
import isAuth from "../../Utils/isAuth";
import {
  getFavorite,
  addfavorite,
  deletefavorite,
} from "./../../Redux/homepage/HomePageAction";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useParams } from "react-router-dom";
import PopUp from "../../Components/PopUp/PopUp";
import ClickAndCollect from "../ClickAndCollect/ClickAndCollect";
import "react-notifications/lib/notifications.css";
import Loader from "../../Components/Loader/Loader";
import SimpleImageSlider from "react-simple-image-slider";

function FeaturedItems() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [source, setSource] = useState("");
  const [favoriteitem, setFavorite] = useState(false);
  const [favoriteid, setFavoriteid] = useState();
  const [addToCardAction, setAddToCardAction] = useState(false);
  const { oneproduct, loading } = useSelector((state) => state.ProductsReducer);
  const { card, error } = useSelector((state) => state.CardReducer);
  const { favorite } = useSelector((state) => state.HomeReducer);
  const { featureprod } = useSelector((state) => state.ProductsReducer);
  const id2 = featureprod?.data?.data?.product?.id;


  useEffect(() => {
    dispatch(getfeatureproducg(id));
    dispatch(getFavorite());
  }, [dispatch]);
  const [onepro, setonepro] = useState([]);
  useEffect(() => {
    setonepro(featureprod?.data?.data?.product);
  }, [featureprod]);


  const [mainpic, setMainpic] = useState();
  useEffect(() => {
    setMainpic(onepro?.img_url);
  }, [dispatch, onepro]);

  const images = [];

  featureprod?.data?.images?.map((product, index) => {
    images.push({ url: product.image })
  })


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (open) => {
    setOpen(!open);
  };
  const handleclose = () => {
    setOpen(false);
  };



  useEffect(() => {
    dispatch(getFavorite());
  }, [dispatch]);

  useEffect(() => {
    let actionDispatched = false;
    if (addToCardAction && !loading) {
      if (!actionDispatched && card.length != 0 && error === null && isAuth()) {

        NotificationManager.success("Cart created successfully", "Success");

        actionDispatched = true;
      } else if (error) {
        NotificationManager.error(error);
      }
      setAddToCardAction(false);
    }
  }, [loading, card, error, addToCardAction]);
  const handleFavorite = () => {
    if (!isAuth()) {
      NotificationManager.error("You need to login first");
      return;
    }
    dispatch(addfavorite(id2));
    setFavorite(true);
    NotificationManager.success(
      "Item added to Wishlist successfully",
      "Success"
    );
  };
  const handleDeleteFavorite = () => {
    if (!isAuth()) {
      NotificationManager.error("You need to login first");
      return;
    }
    setFavorite(false);
    dispatch(deletefavorite(favoriteid));
    NotificationManager.warning(
      "Item Removed from Wishlist successfully",
      "Success"
    );
  };



  useEffect(() => {
    {
      favorite?.data?.data?.map((item) => {
        if (item?.product_id == id2) {
          setFavorite(true);
          setFavoriteid(item?.id);
        } else {
          setFavorite(false);
        }
      });
    }
  }, [id2, favorite]);
  if (loading) { return <Loader /> }


  return (
    <>
      {open && <PopUp setOpen={setOpen} body={<ClickAndCollect />}></PopUp>}
      <NotificationContainer />
      <div className="featured_items_main_container">
        <div className="featured_items_container_1">
          <div className="featured_items_container_info1">
            <div className="featured_items_container_info">
              <img
                src={
                  process.env.REACT_APP_API + "/brand/" + onepro?.brand?.img_url
                }
                alt="brandimage"
                style={{ width: "13rem" }}

              />
              <div className="threematerial2">

                <button
                  className="inquiry1"
                  onClick={() => {
                    if(isAuth()){
                      dispatch(addToCard(id2));
                      setAddToCardAction(true);
                    }else{
                      NotificationManager.error("You need to login first");

                    }

                  }}
                >
                  ADD TO CART
                </button>


                {favoriteitem && (
                  <div onClick={() => handleDeleteFavorite()} className="heart">
                    <AiFillHeart size={30} />
                  </div>
                )}
                {!favoriteitem && (
                  <div onClick={() => handleFavorite()} className="heart">
                    <AiOutlineHeart size={30} />
                  </div>
                )}
              </div>
              <div className="featured_item_code">{onepro?.itemcode}</div>
              <div className="featured_item_details_title">{onepro?.title}</div>
              <div className="swatch_details">{onepro?.description}</div>
              <div className="buttons_div"></div>
            </div>
          </div>
          <div className="featured_items_container_img">
            <img
              src={process.env.REACT_APP_API + "/product/" + mainpic}
              alt="Featured_Item"
              className="Featured_Item"
            />
          </div>
        </div>
        <div className="featured_items_container_3">
          <div className="heading_titles">
            <div className="heading_title_2">from different sides</div>
          </div>
        </div>

        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider black-divider"></div>
        </div>
        {/* <div className="carcont">
          {featureprod?.data?.images[0] && (
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={3000}
              showDots={true}
              containerClass="carousel-containertest"
              swipeable={false}
              draggable={false}
              infinite={true}
            >
              {featureprod?.data?.images?.map((product, index) => (
                <img
                  key={index}
                  src={product?.image}
                  alt="carousel"
                  className="carousel"
                />
              ))}
            </Carousel>
          )}
        </div> */}

        <div className="silder-conatiner">

          <SimpleImageSlider
            width={window.innerWidth > 789 ? 600 : 300}
            height={window.innerWidth > 789 ? 350 : 350}
            autoPlay={true}
            images={images}
            showBullets={false}
            showNavs={true}
            className="slider-content"
          />
        </div>

        <div className="featured_items_container_2">
          <div className="featured_items_Video">
            {featureprod?.data?.data?.video_url && (
              <video
                autoPlay
                playsInline
                muted
                loop
                className="featured_items_vid"
                width="930px"
                height="700px"
              >

                <source
                  src={featureprod?.data?.data?.video_url}
                  type="video/mp4"
                />
              </video>
            )}
          </div>
          {/* <div className="featured_items_titles"> case </div>
          <div className="horizontal_divider_div small_divider">
            <div className="horizontal_divider"></div>
          </div> */}
          {/* <div className="items_specifications_label">
            {featureprod?.data?.data?.case}
          </div>

          <div className="featured_items_content_container">
            <div className="items_specifications">
              <div className="items_specifications_div">
                <div className="featured_items_titles">material</div>
                <div className="horizontal_divider_div small_divider">
                  <div className="horizontal_divider"></div>
                </div>
                <div className="items_specifications_label">
                  {featureprod?.data?.data?.material}
                </div>

                <div className="items_specifications_val"></div>
              </div>
              <div className="items_specifications_div">
                <div className="featured_items_titles">size</div>
                <div className="horizontal_divider_div small_divider">
                  <div className="horizontal_divider"></div>
                </div>
                <div className="items_specifications_label">
                  {featureprod?.data?.data?.size}
                </div>
                <div className="items_specifications_val"></div>
              </div>
              <div className="items_specifications_div">
                <div className="featured_items_titles">WATER RESISTANCE</div>
                <div className="horizontal_divider_div small_divider">
                  <div className="horizontal_divider"></div>
                </div>
                <div className="items_specifications_val">
                  {featureprod?.data?.data?.waterResistance}
                </div>
              </div>
            </div>

            <div className="items_specifications">
              <div className="items_specifications_div">
                <div className="items_specifications_label">
                  <div className="featured_items_titles"> bracelet </div>
                  <div className="horizontal_divider_div small_divider">
                    <div className="horizontal_divider"></div>
                  </div>
                  <div className="items_specifications_label">
                    {featureprod?.data?.data?.bracelet}
                  </div>
                </div>

                <div className="items_specifications_content"></div>
              </div>
              <div className="items_specifications_div">
                <div className="items_specifications_label">
                  <div className="featured_items_titles"> GLASS </div>
                  <div className="horizontal_divider_div small_divider">
                    <div className="horizontal_divider"></div>
                  </div>
                  <div className="items_specifications_label">
                    {featureprod?.data?.data?.glass}
                  </div>
                </div>
                <div className="items_specifications_content"></div>
              </div>
              <div className="items_specifications_div">
                <div className="items_specifications_label">
                  <div className="featured_items_titles"> dial </div>
                  <div className="horizontal_divider_div small_divider">
                    <div className="horizontal_divider"></div>
                  </div>
                  <div className="items_specifications_label">
                    {featureprod?.data?.data?.dial}
                  </div>
                </div>
                <div className="items_specifications_content"></div>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="featured_items_container_3">
          <div className="heading_titles">
            <div className="heading_title_2">from different sides</div>
          </div>
        </div>

        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider black-divider"></div>
        </div>
        {/* <div className="carcont">
          {featureprod?.data?.images[0] && (
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={3000}
              showDots={true}
              containerClass="carousel-containertest"
              swipeable={false}
              draggable={false}
              infinite={true}
            >
              {featureprod?.data?.images?.map((product, index) => (
                <img
                  key={index}
                  src={product?.image}
                  alt="carousel"
                  className="carousel"
                />
              ))}
            </Carousel>
          )}
        </div> */}
        {/* 
        <div className="silder-conatiner">

          <SimpleImageSlider
            width={window.innerWidth > 789 ? 600 : 300}
            height={window.innerWidth > 789 ? 350 : 350}
            autoPlay={true}
            images={images}
            showBullets={false}
            showNavs={true}
            className="slider-content"
          />
        </div>  */}



        <div className="section_details">
          <div className="featured_items_container_1">
            <div className="featured_items_container_info1">
              <div className="featured_items_container_info">
                <div className="featured_item_details_title">
                  {onepro?.brand?.name}
                </div>
                <div className="swatch_details">
                  {onepro?.brand?.description}
                </div>
              </div>
            </div>
            <div className="featured_items_container_img">
              <img
                src={
                  process.env.REACT_APP_API + "/brand/" + onepro?.brand?.img_url
                }
                alt="carousel"
                className="img_style"
              />
            </div>
          </div>
        </div>
        <div className="heading_titles">
          <div className="heading_title_2">related products</div>
        </div>
        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider"></div>
        </div>

        <div className="watching_info_display">
          {featureprod?.data?.relatedProducts?.map((product, index) => (
            <WatchesViewCard
              key={index}
              link={product.id}
              image={product.img_url} // Assuming the image is part of the product object

              brand={product.title}
              price={product.price}
            />
          ))}
        </div>

        <div className="buy_div">
          <div className="buy_div_container">
            <GoLocation size="2rem" />
            <div className="buy_div_content_1">Buy from a store?</div>
            <div className="buy_div_content_2">
              From planter materials to style options, discover which.
            </div>
            <button
              className="send_inquery_btn"
              onClick={() => handleOpen(open)}
            >
              CLICK & COLLECT
            </button>
          </div>
          {/* <div className="vertical_divider_div">
            <div className="vertical_divider"></div>
          </div> */}
          {!onepro?.brand?.name === "Audemars Piguet" && <div className="buy_div_container">
            <GoGift size="2rem" />
            <div className="buy_div_content_1">Send as a gift?</div>
            <div className="buy_div_content_2">
              From planter materials to style options, discover which.
            </div>
            <button className="send_inquery_btn"
              onClick={() => navigate(`/Gift/${id}`)}
            >send as a gift</button>
          </div>}

        </div>
      </div>
    </>
  );
}

export default FeaturedItems;
