import { React, useState, useEffect } from "react";
import { GetJobById } from "../../Redux/Job/JobActions";
import { useDispatch, useSelector } from "react-redux";
import "./VacanciesDetailedForm.css";

function VacanciesDetailedForm(props) {
  const { job } = useSelector((Store) => Store.JobReducer);
  const [jobData, setJobData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetJobById(props.jobId));
  }, []);

  useEffect(() => {
    if (job) {
      setJobData(job);
    }
  }, [job]);

  return (
    <>
      <div className="vacancy_details_main_div">
        <form>
          <div className="vacancy_details_title">{jobData?.title}</div>

          <div className="vacancy_details_form_row">
            <div className="vacancy_details_form_row_col">
              <label className="vacancy_details_form_labels">Job Title</label>
              <input
                disabled
                value={jobData?.title}
                type="text"
                placeholder="Job Title"
                className="vacancy_details_input"
              />
            </div>
            <div className="vacancy_details_form_row_col">
              <label className="vacancy_details_form_labels">Division</label>
              <input
                disabled
                value={jobData?.division}
                type="text"
                placeholder="Division"
                className="vacancy_details_input"
              />
            </div>
            <div className="vacancy_details_form_row_col">
              <label className="vacancy_details_form_labels">Country</label>
              <input
                disabled
                value={jobData?.country}
                type="text"
                placeholder="Job Title"
                className="vacancy_details_input"
              />
            </div>
          </div>

          <div className="vacancy_details_form_row">
            <div className="vacancy_details_form_row_col">
              <label className="vacancy_details_form_labels">
                Posting Date
              </label>
              <input
                disabled
                value={jobData?.posting_date}
                type="text"
                placeholder="Division"
                className="vacancy_details_input"
              />
            </div>
            <div className="vacancy_details_form_row_col">
              <label className="vacancy_details_form_labels">
                Closing Date
              </label>
              <input
                disabled
                value={jobData?.end_date}
                type="text"
                placeholder="Division"
                className="vacancy_details_input"
              />
            </div>
          </div>

          <div className="vacancy_details_form_row">
            <div className="vacancy_details_form_row_col1">
              <label className="vacancy_details_form_labels">Comment</label>
              <textarea
                disabled
                className="vacancy_details_input1"
                value={jobData?.comment}
                rows={5} // Adjust the number of rows as needed
                cols={40} // Adjust the number of columns as needed
                placeholder="Comment"
              />
            </div>
          </div>

          <div className="vacancy_details_form_row vacancy_details_form_row_submit_btn">
            <button
              className="submit_btn apply_btn"
              onClick={() => {
                props.applyHandle();
              }}
            >
              Apply
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default VacanciesDetailedForm;
