import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  billings: [],
  billing: [],
  payment: [],
  loading: false,
  loading1: false,
  error: null,
};

const BillingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    BillingRequest(state, action) {
      state.loading = true;
    },
    BillingSuccess(state, action) {
      state.loading = false;
      state.billings = action.payload;
    },
    BillingFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    BillingByIdRequest(state, action) {
      state.loading = true;
    },
    BillingByIdSuccess(state, action) {
      state.loading = false;
      state.billing = action.payload;
    },
    BillingByIdFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    paymentRequest(state, action) {
      state.loading = true;
    },
    paymentSuccess(state, action) {
      state.loading = false;
      state.payment = action.payload;
    },
    paymentFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const BillingAction = BillingSlice.actions;
export default BillingSlice;
