import "./ProductDetails.css";
function DetailAttribute({ brandName, oneproduct }) {
  return (
    <div style={{ marginLeft: "0.5rem" }}>
      {brandName === "Montblanc" && (
        <>
          <ul>


            {oneproduct?.data?.data?.MontblacAttribute?.Content !== "NULL" && (
              <li>
                <span className="attribute-label">Content: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Content}
                </div>
              </li>
            )}



            {oneproduct?.data?.data?.MontblacAttribute?.Barrel !== "NULL" && (
              <li>
                <span className="attribute-label">Barrel: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Barrel}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Bezel !== "NULL" && (
              <li>
                <span className="attribute-label">Bezel: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Bezel}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.BraceletColour !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Bracelet Colour: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.BraceletColour}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.BraceletMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Bracelet Material: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.BraceletMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.Buckle !== "NULL" && (
              <li>
                <span className="attribute-label">Buckle: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Buckle}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Calibre !== "NULL" && (
              <li>
                <span className="attribute-label">Calibre: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Calibre}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Cap !== "NULL" && (
              <li>
                <span className="attribute-label">Cap: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Cap}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.CaseMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Case Material: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.CaseMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.Clip !== "NULL" && (
              <li>
                <span className="attribute-label">Clip: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Clip}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Color}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Crown !== "NULL" && (
              <li>
                <span className="attribute-label">Crown: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Crown}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Dial !== "NULL" && (
              <li>
                <span className="attribute-label">Dial: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Dial}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Diameter !== "NULL" && (
              <li>
                <span className="attribute-label">Diameter: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Diameter}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Dimensions !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Dimensions: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.Dimensions}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.FittingsEmblem !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Fittings Emblem: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.FittingsEmblem}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.Frequency !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Frequency: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.Frequency}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.Genders !== "NULL" && (
              <li>
                <span className="attribute-label">Genders: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Genders}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.Hands !== "NULL" && (
              <li>
                <span className="attribute-label">Hands: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Hands}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.LeatherMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Leather Material: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.LeatherMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.Lining !== "NULL" && (
              <li>
                <span className="attribute-label">Lining: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Lining}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.MovementType !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Movement Type: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.MovementType}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.PowerReserve !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Power Reserve: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.PowerReserve}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.SapphireCrystal !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Sapphire Crystal: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.SapphireCrystal}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.ShortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.ShortDescription}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.MontblacAttribute?.StrapMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Strap Material: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.StrapMaterial}
                  </div>
                </li>
              )}


            {oneproduct?.data?.data?.MontblacAttribute?.Type !== "NULL" && (
              <li>
                <span className="attribute-label">Type: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.MontblacAttribute?.Type}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.MontblacAttribute?.WaterResistant !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Water Resistant: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.MontblacAttribute?.WaterResistant}
                  </div>
                </li>
              )}
          </ul>
        </>
      )}
      {brandName === "Swatch" && (
        <>
          <ul>


            {oneproduct?.data?.data?.SwatchAttribute?.Content !== "NULL" && (
              <li>
                <span className="attribute-label">Content: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Content}
                </div>
              </li>
            )}


            {oneproduct?.data?.data?.SwatchAttribute?.Bridge !== "NULL" && (
              <li>
                <span className="attribute-label">Bridge: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Bridge}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseColor !== "NULL" && (
              <li>
                <span className="attribute-label">Case Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.CaseColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseHeight !== "NULL" && (
              <li>
                <span className="attribute-label">Case Height: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.CaseHeight}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Case Material: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.CaseMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseShape !== "NULL" && (
              <li>
                <span className="attribute-label">Case Shape: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.CaseShape}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseThickness !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Case Thickness: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.CaseThickness}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.CaseWidth !== "NULL" && (
              <li>
                <span className="attribute-label">Case Width: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.CaseWidth}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.ClaspMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Clasp Material: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.ClaspMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.ClaspType !== "NULL" && (
              <li>
                <span className="attribute-label">Clasp Type: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.ClaspType}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.Color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Color}
                </div>
              </li>
            )}



            {oneproduct?.data?.data?.SwatchAttribute?.DialColor !== "NULL" && (
              <li>
                <span className="attribute-label">Dial Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.DialColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.Genders !== "NULL" && (
              <li>
                <span className="attribute-label">Genders: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Genders}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.ItemColor !== "NULL" && (
              <li>
                <span className="attribute-label">Item Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.ItemColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.ItemFunction !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Item Function: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.ItemFunction}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.LensColor !== "NULL" && (
              <li>
                <span className="attribute-label">Lens Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.LensColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.LensUV !== "NULL" && (
              <li>
                <span className="attribute-label">Lens UV: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.LensUV}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.Movement !== "NULL" && (
              <li>
                <span className="attribute-label">Movement: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Movement}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.ShortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.ShortDescription}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.Size !== "NULL" && (
              <li>
                <span className="attribute-label">Size: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Size}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.SizesAvailable !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Sizes Available: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.SizesAvailable}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.StrapColor !== "NULL" && (
              <li>
                <span className="attribute-label">Strap Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.StrapColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.StrapMaterial !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Strap Material: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.StrapMaterial}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.SwatchAttribute?.Style !== "NULL" && (
              <li>
                <span className="attribute-label">Style: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Style}
                </div>
              </li>
            )}


            {oneproduct?.data?.data?.SwatchAttribute?.Warranty !== "NULL" && (
              <li>
                <span className="attribute-label">Warranty: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.SwatchAttribute?.Warranty}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.SwatchAttribute?.WaterResistance !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Water Resistance: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.SwatchAttribute?.WaterResistance}
                  </div>
                </li>
              )}
          </ul>
        </>
      )}
      {brandName === "Lancel" && oneproduct?.data?.data?.LancelAttribute && (
        <>
          <ul>



            {oneproduct?.data?.data?.LancelAttribute?.Content !== "NULL" && (
              <li>
                <span className="attribute-label">Content: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Content}
                </div>
              </li>
            )}


            {oneproduct?.data?.data?.LancelAttribute?.BackCompartment !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Back Compartment: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.LancelAttribute?.BackCompartment}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.LancelAttribute?.Care !== "NULL" && (
              <li>
                <span className="attribute-label">Care: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Care}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.Color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Color}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.Compartments !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Compartments: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.LancelAttribute?.Compartments}
                  </div>
                </li>
              )}



            {oneproduct?.data?.data?.LancelAttribute?.detail !== "NULL" && (
              <li>
                <span className="attribute-label">detail-pro: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.detail}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.Dimension !== "NULL" && (
              <li>
                <span className="attribute-label">Dimension: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Dimension}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.FrontCompartment !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Front Compartment: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.LancelAttribute?.FrontCompartment}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.LancelAttribute?.Genders !== "NULL" && (
              <li>
                <span className="attribute-label">Genders: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Genders}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.InternalPockets !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Internal Pockets: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.LancelAttribute?.InternalPockets}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.LancelAttribute?.Lining !== "NULL" && (
              <li>
                <span className="attribute-label">Lining: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Lining}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.MadeIn !== "NULL" && (
              <li>
                <span className="attribute-label">Made In: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.MadeIn}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.Material !== "NULL" && (
              <li>
                <span className="attribute-label">Material: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Material}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.Ring !== "NULL" && (
              <li>
                <span className="attribute-label">Ring: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Ring}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.LancelAttribute?.ShortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.LancelAttribute?.ShortDescription}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.LancelAttribute?.Strap !== "NULL" && (
              <li>
                <span className="attribute-label">Strap: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.LancelAttribute?.Strap}
                </div>
              </li>
            )}


          </ul>
        </>
      )}
      {brandName === "Flik Flak" && oneproduct?.data?.data?.FlikFlakAttribute && (
        <>
          <ul>


            {oneproduct?.data?.data?.FlikFlakAttribute?.Content !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Content: &nbsp; &nbsp;
                  </span>
                  <div className="detail-pro">
                    {"  " + oneproduct?.data?.data?.FlikFlakAttribute?.Content}
                  </div>
                </li>
              )}



            {oneproduct?.data?.data?.FlikFlakAttribute?.Color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.FlikFlakAttribute?.Color}
                </div>
              </li>
            )}


            {oneproduct?.data?.data?.FlikFlakAttribute?.Genders !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Genders: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.FlikFlakAttribute?.Genders}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.FlikFlakAttribute?.Safe !== "NULL" && (
              <li>
                <span className="attribute-label">Safe: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.FlikFlakAttribute?.Safe}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.FlikFlakAttribute?.ShockResistant !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Shock Resistant: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.FlikFlakAttribute?.ShockResistant}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.FlikFlakAttribute?.ShortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {
                      oneproduct?.data?.data?.FlikFlakAttribute
                        ?.ShortDescription
                    }
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.FlikFlakAttribute?.SwissMadeWatch !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Swiss Made Watch: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.FlikFlakAttribute?.SwissMadeWatch}
                  </div>
                </li>
              )}
          </ul>
        </>
      )}
      {brandName === "Omega" && oneproduct?.data?.data?.OmegaAttribute && (
        <>
          <ul>

            {oneproduct?.data?.data?.OmegaAttribute?.Content !== "NULL" && (
              <li>
                <span className="attribute-label">Content: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Content}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.BatteryLife !== "NULL" && (
              <li>
                <span className="attribute-label">Battery Life: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.BatteryLife}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.BetweenLugs !== "NULL" && (
              <li>
                <span className="attribute-label">Between Lugs: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.BetweenLugs}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.Bracelet !== "NULL" && (
              <li>
                <span className="attribute-label">Bracelet: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Bracelet}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.Calibre !== "NULL" && (
              <li>
                <span className="attribute-label">Calibre: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Calibre}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.Case !== "NULL" && (
              <li>
                <span className="attribute-label">Case: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Case}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.CaseDiameter !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Case Diameter: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.OmegaAttribute?.CaseDiameter}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.OmegaAttribute?.Color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Color}
                </div>
              </li>
            )}



            {oneproduct?.data?.data?.OmegaAttribute?.Crystal !== "NULL" && (
              <li>
                <span className="attribute-label">Crystal: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Crystal}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.DialColor !== "NULL" && (
              <li>
                <span className="attribute-label">Dial Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.DialColor}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.Features !== "NULL" && (
              <li>
                <span className="attribute-label">Features: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Features}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.Genders !== "NULL" && (
              <li>
                <span className="attribute-label">Genders: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.Genders}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.ProductType !== "NULL" && (
              <li>
                <span className="attribute-label">Product Type: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.OmegaAttribute?.ProductType}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.OmegaAttribute?.ShortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.OmegaAttribute?.ShortDescription}
                  </div>
                </li>
              )}


            {oneproduct?.data?.data?.OmegaAttribute?.WaterResistance !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Water Resistance: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.OmegaAttribute?.WaterResistance}
                  </div>
                </li>
              )}
          </ul>
        </>
      )}
      {brandName === "Audemars Piguet" && oneproduct?.data?.data?.AudemarAttribute && (
        <>
          <ul>

            {oneproduct?.data?.data?.AudemarAttribute?.content !== "NULL" && (
              <li>
                <span className="attribute-label">Content: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.content}
                </div>
              </li>
            )}


            {oneproduct?.data?.data?.AudemarAttribute?.bracelet !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Bracelet: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.bracelet}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.calibre !== "NULL" && (
              <li>
                <span className="attribute-label">Calibre: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.calibre}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.AudemarAttribute?.case !== "NULL" && (
              <li>
                <span className="attribute-label">Case: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.case}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.AudemarAttribute?.caseSize !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Case Size: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.caseSize}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.color !== "NULL" && (
              <li>
                <span className="attribute-label">Color: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.color}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.AudemarAttribute?.complications !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Complications: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.complications}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.dial !== "NULL" && (
              <li>
                <span className="attribute-label">Dial: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.dial}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.AudemarAttribute?.function !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Function: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.function}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.genders !== "NULL" && (
              <li>
                <span className="attribute-label">Genders: &nbsp;</span>
                <div className="detail-pro">
                  {oneproduct?.data?.data?.AudemarAttribute?.genders}
                </div>
              </li>
            )}

            {oneproduct?.data?.data?.AudemarAttribute?.mechanism !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Mechanism: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.mechanism}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.numberOfJewels !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Number of Jewels: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.numberOfJewels}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.numberOfParts !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Number of Parts: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.numberOfParts}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.powerReserve !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Power Reserve: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.powerReserve}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.shortDescription !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Short Description: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.shortDescription}
                  </div>
                </li>
              )}

            {oneproduct?.data?.data?.AudemarAttribute?.thickness !==
              "NULL" && (
                <li>
                  <span className="attribute-label">Thickness: &nbsp;</span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.thickness}
                  </div>
                </li>
              )}



            {oneproduct?.data?.data?.AudemarAttribute?.waterResistance !==
              "NULL" && (
                <li>
                  <span className="attribute-label">
                    Water Resistance: &nbsp;
                  </span>
                  <div className="detail-pro">
                    {oneproduct?.data?.data?.AudemarAttribute?.waterResistance}
                  </div>
                </li>
              )}
          </ul>
        </>
      )}
    </div>
  );
}
export default DetailAttribute;
