import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: [],
  otpMessage : [],
  loading: false,
  error: null,
  forgetPassMessage : [],
  forgetPassMessageLoading: false,
  forgetPassMessageError: null,
  changePasswordMessage : [],
  changePasswordLoading: false,
  changePasswordError: null,
  token:[]
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    RegisterRequest(state, action) {
      state.loading = true;
    },
    RegisterSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    RegisterFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    ProtectedRequest(state, action) {
      state.loading = true;
    },
    ProtectedSuccess(state, action) {
      state.loading = false;
      state.token = action.payload;
    },
    ProtectedFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    LoginRequest(state, action) {
      state.loading = true;
    },
    LoginSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    LoginFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    Logout(state, action) {
      state.user = [];
    },

    EditRequest(state, action) {
      state.loading = true;
    },
    EditSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    EditFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    sendOtpRequest(state, action) {
      state.loading = true;
    },
    sendOtpSuccess(state, action) {
      state.loading = false;
      state.otpMessage = action.payload;
    },
    sendOtpFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    forgetPasswordRequest(state, action) {
      state.forgetPassMessageLoading = true;
    },
    forgetPasswordSuccess(state, action) {
      state.forgetPassMessageLoading = false;
      state.forgetPassMessage = action.payload;
    },
    forgetPasswordFail(state, action) {
      state.forgetPassMessageLoading = false;
      state.forgetPassMessageError = action.payload;
    },

    changePasswordRequest(state, action) {
      state.changePasswordLoading = true;
      state.changePasswordMessage = [];
      state.changePasswordError = null;
    },
    changePasswordSuccess(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordMessage = action.payload;
      state.changePasswordError = null;
    },
    changePasswordFail(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordMessage = [];
      state.changePasswordError = action.payload;
    },


  },
});

export const userAction = userSlice.actions;
export default userSlice;
