import React from "react";
import "./PopUp.css";

function PopUp({ body, setOpen, cont }) {
  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setOpen(false)}>
          &times;
        </span>
        {body && <p className="body_text">{body}</p>}
        {cont && <div className="body_cont">{cont}</div>}
      </div>
    </div>
  );
}

export default PopUp;
