import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Section2.css";
import WatchesViewCard from "../../../Components/WatchesViewCard/WatchesViewCard";
import watchimg from "../../../assets/Images/Home/IMAGE (2).png";
import { useNavigate } from "react-router-dom";
import { getBestSeller } from "../../../Redux/homepage/HomePageAction";
import Loader from "./../../../Components/Loader/Loader";

function Section2() {
  const navigate = useNavigate();
  const [buttonNames, setButtonNames] = useState([
    "LUXURY",
    "FASHION",
    "KIDS",
    "LIFESTYLE",
  ]);

  const [buttonStates, setButtonStates] = useState([
    true,
    ...Array.from({ length: buttonNames.length - 1 }, () => false),
  ]);
  const [clickedButtonName, setClickedButtonName] = useState("LUXURY");
  const handleClick = (index) => {
    const updatedStates = Array.from(
      { length: buttonNames.length },
      () => false
    );
    updatedStates[index] = true;
    setButtonStates(updatedStates);
    setClickedButtonName(buttonNames[index]);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBestSeller(clickedButtonName));
  }, [dispatch, clickedButtonName]);

  const { bestSeller, loading } = useSelector((state) => state.HomeReducer);

  const [rec, setRec] = useState([]);
  useEffect(() => {
    if (bestSeller) {
      setRec(bestSeller);
    }
  }, [bestSeller]);



  return (
    <div className="Main_Section2_div">
      <div className="Section2_Header">
        <div className="Section2_Header2">BEST SELLERS</div>
        <div className="Section2_Headeer_underline" />
      </div>
      <div className="Section2_buttons">
        <div className="Section2_button1">
          {buttonNames.map((name, index) => (
            <button
              key={index}
              className={
                buttonStates[index]
                  ? "Section2_button_active"
                  : "Section2_button"
              }
              onClick={() => handleClick(index)}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="Section2_Cards">
          {bestSeller?.data?.map((item, index) => (
            <WatchesViewCard
              key={index}
              link={item.id} // You should use a unique key for each element in the array
              image={item.img_url} // Replace with the actual property name from your data
              // name={item.description} // Replace with the actual property name from your data
              brand={item.title} // Replace with the actual property name from your data
              price={item.price} // Replace with the actual property name from your data
              id={item.brand_id}
            />
          ))}
        </div>
      )}
      <div className="Section2_button2" onClick={() => navigate(`/products`)}>
        <button className="Section2_button_view">VIEW ALL</button>
      </div>
    </div>
  );
}

export default Section2;
