import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: [],
  loading: false,
  error: null,
  job: [],
  jobLoading: false,
  jobError: null,

};

const JobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    JobsRequest(state, action) {
      state.loading = true;
      state.jobs = [];
      state.error = null;
    },
    JobsSuccess(state, action) {
      state.loading = false;
      state.jobs = action.payload;
      state.error = null;
    },
    JobsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.jobs = [];
    },

    JobRequest(state, action) {
      state.jobLoading = true;
      state.job = [];
      state.jobError = null;
    },
    JobSuccess(state, action) {
      state.jobLoading = false;
      state.job = action.payload;
      state.jobError = null;
    },
    JobFail(state, action) {
      state.jobLoading = false;
      state.error = action.payload;
      state.jobError = [];
    },

  },
});

export const JobAction = JobSlice.actions;
export default JobSlice;
