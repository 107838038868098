import { React, useState } from "react";
import "./WatchesInfo.css";
import watch_small from "../../assets/Images/Watches/SwatchSmall.png";
import watch_image from "../../assets/Images/Watches/Swatch.png";
import Swatch_icon from "../../assets/Images/Watches/SwatchIcon.png";
import { AiOutlineHeart } from "react-icons/ai";
import { GoLocation, GoGift } from "react-icons/go";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import watchimg from "../../assets/Images/Home/IMAGE (2).png";
import PopUp from "../../Components/PopUp/PopUp";
import ClickAndCollect from "../ClickAndCollect/ClickAndCollect";

function WatchesInfo() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="watches_info_container">
        <div className="file_path_container">
          <div className="path_disabled_tab">home</div>
          <div className="path_divider">/</div>
          <div className="path_disabled_tab">watches</div>
          <div className="path_divider">/</div>
          <div className="path_current_tab">lost in the sea</div>
        </div>
        <div className="watch_infos_div">
          <div className="watch_image_div">
            <div className="small_watches">
              <img
                src={watch_small}
                alt="watch_small"
                className="watch_small_img"
              />
              <img
                src={watch_small}
                alt="watch_small"
                className="watch_small_img"
              />
              <img
                src={watch_small}
                alt="watch_small"
                className="watch_small_img"
              />
            </div>
            <img src={watch_image} alt="watch_small" className="watch_img" />
          </div>
          <div className="watch_infos_details">
            <img src={Swatch_icon} alt="watch_icon" className="watch_icon" />
            <div className="watch_infos_details_title">
              watch name goes here sample
            </div>
            <div className="watch_code">YVS475</div>
            <div className="swatch_details">
              From planter materials to style options, discover which planter is
              best for your space. From planter materials to style options,
              discover which planter is best for your space.
            </div>
            <div className="buttons_div">
              <button className="add_to_chart_btn">add to chart</button>
              <button className="send_inquery_btn">send inquiry</button>
              <AiOutlineHeart className="header-icons" size={30} />
            </div>
          </div>
        </div>
        <div className="horizontal_divider_div">
          <div className="horizontal_divider"></div>
        </div>
        <div className="buy_div">
          <div className="buy_div_container">
            <GoLocation size="2rem" />
            <div className="buy_div_content_1">Buy from a store?</div>
            <div className="buy_div_content_2">
              From planter materials to style options, discover which.
            </div>
            <button className="send_inquery_btn" onClick={handleOpen}>
              CLICK & COLLECT
            </button>
          </div>
          <div className="vertical_divider_div">
            <div className="vertical_divider"></div>
          </div>
          <div className="buy_div_container">
            <GoGift size="2rem" />
            <div className="buy_div_content_1">Send as a gift?</div>
            <div className="buy_div_content_2">
              From planter materials to style options, discover which.
            </div>
            <button className="send_inquery_btn">send as a gift</button>
          </div>
        </div>
        <div className="heading_titles">
          <div className="heading_title_2">related products</div>
        </div>
        <div className="horizontal_divider_div small_divider">
          <div className="horizontal_divider"></div>
        </div>

        <div className="watching_info_display">
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
        </div>
        <div className="view_btn_container">
          <button className="view_btn">VIEW ALL</button>
        </div>
        {open && <PopUp setOpen={setOpen} body={<ClickAndCollect />}></PopUp>}
      </div>
    </>
  );
}

export default WatchesInfo;
