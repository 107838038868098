import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ProCategory: [],
  PreProduct: [],
  // productprecat: [],
  // oneproduct: [],
  // products: [],
  // featureprod: [],
  loading: false,
  error: null,
};

const PreoSlice = createSlice({
  name: "Preo",
  initialState,
  reducers: {
    CategoryRequest(state, action) {
      state.loading = true;
    },
    CategorySuccess(state, action) {
      state.loading = false;
      state.ProCategory = action.payload;
    },
    CategoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    PreProductRequest(state, action) {
      state.loading = true;
      state.PreProduct = [];
    },
    PreProductSuccess(state, action) {
      state.loading = false;
      state.PreProduct = action.payload;
    },
    PreProductFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.PreProduct = [];
    },
    // oneproductRequest(state, action) {
    //   state.loading = true;
    // },
    // oneproductSuccess(state, action) {
    //   state.loading = false;
    //   state.oneproduct = action.payload;
    // },
    // oneproductFail(state, action) {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
    // ProductsRequest(state, action) {
    //   state.loading = true;
    // },
    // ProductsSuccess(state, action) {
    //   state.loading = false;
    //   state.products = action.payload;
    // },
    // ProductsFail(state, action) {
    //   state.loading = false;
    //   state.error = action.payload;
    // },

    // featureprodRequest(state, action) {
    //   state.loading = true;
    // },
    // featureprodSuccess(state, action) {
    //   state.loading = false;
    //   state.featureprod = action.payload;
    // },
    // featureprodFail(state, action) {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});

export const PreoAction = PreoSlice.actions;
export default PreoSlice;
