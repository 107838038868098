import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  career: [],
  loading: false,
  error: null,
};

const CareerSlice = createSlice({
  name: "career",
  initialState,
  reducers: {
    CareerRequest(state, action) {
      state.loading = true;
    },
    CareerSuccess(state, action) {
      state.loading = false;
      state.career = action.payload;
    },
    CareerFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const CareerAction = CareerSlice.actions;
export default CareerSlice;
