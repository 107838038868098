import { React, useState } from "react";
import "./BestSellerPage.css";
import { FiFilter } from "react-icons/fi";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import watchimg from "../../assets/Images/Home/IMAGE (2).png";
import Filter from "../../Components/Filter/Filter";
import { IoIosArrowDown } from "react-icons/io";

function BestSellerPage() {
  //{ totalPages, currentPage, onPageChange } add in the main function when backend is ready
  const totalPages = 4;
  const [activePage, setActivePage] = useState(1);
  const query = new URLSearchParams(window.location.search);
  const section = query.get("section");
  const handlePageChange = (pageNum) => {
    setActivePage(pageNum);
  };
  const [sortby, setsortby] = useState(false);

  return (
    <div className="Main_Products">
      <div className="Products_header">
        <span className="section_route"> Home / Watches /</span>{" "}
        <span className="section_name"> {section} </span>
      </div>
      <div className="filter_container_text">BEST SELLERS WATCHES</div>
      <div className="Section2_underline" />

      <div className="Products_container_filters">
        <div className="filter_container2">
          <div className="filter-section2">
            <div className="filter-row">
              <div className="filter-item" onClick={() => setsortby(!sortby)}>
                <div className="filter_container_title"> Sort By </div>
                <div className="filter_arrow">
                  <IoIosArrowDown
                    size={20}
                    className={sortby ? "arrow_down" : "arrow_up"}
                  />
                </div>
              </div>

              {sortby && <div className="Main_filters_card2"></div>}
              <div className="filter-item">
                <div className="filter_container_title">
                  {" "}
                  Showing 1-16 Of 31 Results{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Products_container_products">
        <div className="Products_container">
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
          <WatchesViewCard
            image={watchimg}
            name={"Montblanc 1858 Automatic Chronograph Limited Edition"}
            brand={"MONTBLANC"}
            price={"$2,687"}
          />
        </div>
      </div>
      <div className="Products_container_pagination">
        <div className="pagination_container">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNum) => (
              <span
                key={pageNum}
                className={`pagination_container_item ${
                  pageNum === activePage
                    ? "pagination_container_item_active"
                    : ""
                }`}
                onClick={() => handlePageChange(pageNum)}
              >
                {pageNum}
              </span>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default BestSellerPage;
