import React, { useState, useEffect } from "react";
import "./SingleProduct.css";
import image from "./../../assets/Images/Cart/IMAGE.png";
import swatch from "./../../assets/Images/Cart/image 3.png";
import { AiOutlineHeart } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { AiOutlineGift } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getoneproductpre } from "./../../Redux/Products/ProductsAction";

import { useParams } from "react-router-dom";
import { TbPointFilled } from "react-icons/tb";
import PopUp from "../../Components/PopUp/PopUp";
import BuyPreorder from "../BuyPreorder/BuyPreorder";
import Loader from "./../../Components/Loader/Loader";

function SingleProduct() {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");

  //toogle
  const [open, setOpen] = useState(false);
  const handleOpen = (open) => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(getoneproductpre(id));
  }, [dispatch]);
  const { oneproductpre, loading } = useSelector(
    (state) => state.ProductsReducer
  );
  const [onepro, setonepro] = useState([]);
  useEffect(() => {
    setonepro(oneproductpre?.data?.data);
  }, [oneproductpre]);
  const [mainpic, setMainpic] = useState();
  useEffect(() => {
    setMainpic(onepro?.img_url);
  }, [dispatch, onepro]);
  // const additionalInfoObj = JSON.parse(onepro?.additionalInformation);

  // console.log(additionalInfoObj);
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=71441255`;

    window.open(whatsappUrl, "_blank");
  };
  const handleclose = () => {
    setOpen(false);
  };
  function DisplayAdditionalInformation({ additionalInformation }) {
    // Check if additionalInformation is defined and not null or undefined
    if (additionalInformation) {
      try {
        // Parse the JSON string to a JavaScript object
        const additionalInfoObj = JSON.parse(additionalInformation);

        return (
          <div>
            <ul>
              {Object.entries(additionalInfoObj).map(([key, value]) => (
                <li key={key}>
                  <strong className="strong">{key}:</strong>  {value}
                </li>
              ))}
            </ul>
          </div>
        );
      } catch (error) {
        // Handle the case where additionalInformation is not a valid JSON string
        return <div>Error: Invalid JSON</div>;
      }
    } else {
      return <div>No additional information available</div>;
    }
  }
  return (
    <div>
      {open && (
        <PopUp
          setOpen={setOpen}
          body={<BuyPreorder id={id} onClose={handleclose} />}
        ></PopUp>
      )}
      <div className="rout">
        <div className="homeroute">HOME &nbsp;&nbsp;/</div>
        <div
          className="watchroute"
          onClick={() => {
            navigate(`/categorypre/${onepro?.pre_category?.id}}`);
          }}
        >
          {" "}
          &nbsp;&nbsp; {onepro?.pre_category?.name}
        </div>
        <div className="productroute">
          {" "}
          &nbsp;&nbsp;/ &nbsp;&nbsp; {onepro?.title}
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="image">
            <div className="imagescoloumn">
              {onepro?.pre_productImages?.map((product, index) => (
                <img
                  src={product?.image}
                  className="smallimage"
                  key={index}
                  onClick={() => setMainpic(product?.image)}
                />
              ))}
            </div>
            <div>
              <img src={mainpic} className="image-conto" />
            </div>
          </div>
          <div className="description">
            <div className="code">{onepro?.title}</div>
            <div className="cont1">
              <div className="soldby">SOLD BY</div>
              <div className="partner">{onepro?.sold_by}</div>
            </div>

            <div className="threematerial">
              <button className="inquiry" onClick={handleWhatsAppClick}>
                SEND INQUIRY
              </button>
            </div>

            <div className="title">Condition</div>
            <div className="group">
              <div className="eachone">
                <div>Fair</div>
                <div>
                  {onepro?.Condition != "Fair" && <TbPointFilled className="down" />}
                  {onepro?.Condition == "Fair" && (
                    <TbPointFilled size={28} color="black" />
                  )}
                </div>
              </div>
              <div className="eachone">
                <div>Good</div>
                <div>
                  {onepro?.Condition != "Good" && <TbPointFilled className="downPosition" />}
                  {onepro?.Condition == "Good" && (
                    <TbPointFilled size={28} color="black" />
                  )}
                </div>
              </div>
              <div className="eachone">
                <div>Very Good</div>
                <div>
                  {onepro?.Condition != "Very Good" && <TbPointFilled className="downPosition" />}
                  {onepro?.Condition == "Very Good" && (
                    <TbPointFilled size={28} color="black" />
                  )}
                </div>
              </div>
              <div className="eachone">
                <div>Excellent</div>
                <div>
                  {onepro?.Condition != "Excellent" && <TbPointFilled className="downPosition" />}
                  {onepro?.Condition == "Excellent" && (
                    <TbPointFilled size={28} color="black" />
                  )}
                </div>
              </div>
              <div className="eachone">
                <div>Pristine</div>
                <div>
                  {onepro?.Condition != "Pristine" && <TbPointFilled className="downPosition" />}
                  {onepro?.Condition == "Pristine" && (
                    <TbPointFilled size={28} color="black" />
                  )}
                </div>
              </div>
            </div>

            <div className="title">Description</div>
            <div className="details">{onepro?.description}</div>

            <div className="title">Details</div>
            <div className="details">
              {onepro?.additionalInformation ? (
                <DisplayAdditionalInformation
                  additionalInformation={onepro.additionalInformation}
                />
              ) : (
                <p>No additional information available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleProduct;
