import axios from "axios";
import { ApplyAction } from "./ApplyReducers";

export const applyJob = (JobId , ApplyData) => async (dispatch) => {
  try {
    const formData = {
      first_name: ApplyData.first_name,
      last_name: ApplyData.last_name,
      mobile: ApplyData.mobile,
      email: ApplyData.email,
      cv: ApplyData.cv,
    };

    dispatch(ApplyAction.ApplyRequest());
    const { data } = await axios.post(
      process.env.REACT_APP_API + "/apply/" + JobId ,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(ApplyAction.ApplySuccess(data.data));
  } catch (error) {
    dispatch(ApplyAction.ApplyFail(error));
  }
};
