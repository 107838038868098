import axios from "axios";
import { JobAction } from "./JobReducers";

export const GetJobs = (title, country, type) => async (dispatch) => {
  try {
    dispatch(JobAction.JobsRequest());
    const { data } = await axios.get(
      process.env.REACT_APP_API +
        "/job" +
        (title ? "?title=" + title : "") +
        (country ? "&country=" + country : "") +
        (type ? "&type=" + type : "")
    );
    dispatch(JobAction.JobsSuccess(data.data));
  } catch (error) {
    dispatch(JobAction.JobsFail(error));
  }
};

export const GetJobById = (id) => async (dispatch) => {
  try {
    dispatch(JobAction.JobRequest());
    const { data } = await axios.get(process.env.REACT_APP_API + "/job/" + id);
    dispatch(JobAction.JobSuccess(data.data));
  } catch (error) {
    dispatch(JobAction.JobFail(error));
  }
};
