import React, { useState , useEffect } from 'react';
import "./PasswordResetRequest.css";
import { changePassword } from "../../Redux/Login/Register/LoginActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const { changePasswordError } = useSelector(
    (Store) => Store.userReducer
  );
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    setErrorMessage("");
  }, []);

  useEffect(() => {
    if (changePasswordError != "") {
      setErrorMessage(changePasswordError);
    }
  }, [changePasswordError]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'currentPassword') {
      setCurrentPassword(value);
    } else if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmNewPassword') {
      setConfirmNewPassword(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // You can perform validation here
    if (newPassword !== confirmNewPassword) {
        setErrorMessage('New passwords do not match.');
      return;
    }
    dispatch(changePassword(currentPassword , newPassword , navigate))
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}  className="reset_password_form">
          <label htmlFor="currentPassword" className='input_label_change_pass'>Current Password:</label>
          <input
            type="password"
            id="currentPassword"
            name="currentPassword"
            className="email_input_reset_pass"
            value={currentPassword}
            onChange={handleChange}
            required
          />
          <label htmlFor="newPassword" className='input_label_change_pass'>New Password:</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            className="email_input_reset_pass"
            value={newPassword}
            onChange={handleChange}
            required
          />
          <label htmlFor="confirmNewPassword" className='input_label_change_pass'>Confirm New Password:</label>
          <input
            type="password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            className="email_input_reset_pass"
            value={confirmNewPassword}
            onChange={handleChange}
            required
          />
          {errorMessage && <div className="error_msg">{errorMessage}</div>}
        <button type="submit" className="btn_reset_pass">Change Password</button>
      </form>
    </div>
  );
};

export default ChangePassword;
