import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MoreFiltersSection.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { GetBrands } from "../../Redux/Brand/BrandActions";
import { GetCategories } from "../../Redux/Category/CategoryActions";
import { GetSubCategories } from "../../Redux/Category/CategoryActions";
import { SettingsAction } from "../../Redux/settingsRedux";
import { MdClear } from "react-icons/md";
import { useLocation } from 'react-router-dom';

function MoreFiltersSection(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { brands } = useSelector((Store) => Store.BrandReducer);
  const { categories } = useSelector((Store) => Store.CategoryReducer);

  // categories?.map((category) => {
  //   console.log("hi2", category.id);
  //  dispatch(GetSubCategories(category.id));
  //   console.log("hi", subcategories);
  // });
 
  const [brand, setbrand] = useState(false);
  const [productcat, setproductcat] = useState(false);
  const [gender, setgender] = useState(false);
  const [price, setprice] = useState(false);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const brandVar = queryParams.get('brand');

  useEffect(() => {
    dispatch(GetBrands());
    dispatch(GetCategories());
    if(brandVar){
      console.log(brandVar);
      setSelectedBrands(...selectedBrands, brandVar)
    }
  }, []);

  useEffect(() => {
    let filtersString = "";
    if (selectedBrands.length > 0) {
      filtersString += "brand=" + selectedBrands + "&";
    }
    if (selectedCategories.length > 0) {
      filtersString += "category=" + selectedCategories + "&";
    }
    if (selectedGenders.length > 0) {
      filtersString += "gender=" + selectedGenders + "&";
    }
    if (selectedPrices.length > 0) {
      filtersString += "price=" + selectedPrices + "&";
    }
    if (selectedSubCat.length > 0) {
      filtersString += "subcategory=" + selectedSubCat + "&";
    }
    if (filtersString.length > 0) props.getFilteredProducts(filtersString);
  }, [
    selectedBrands,
    selectedCategories,
    selectedGenders,
    selectedPrices,
    selectedSubCat,
  ]);

  const Products = [
    "Luxury Watches",
    "Fashion Watches",
    "Kids Watches",
    "Lifestyle Accessories",
    "Travel",
    "Pre-O",
  ];

  const Gender = ["Man", "Woman", "Kids"];
  const Price = [
    "$100-$500",
    "$500-$1000",
    "$1000-$2000",
    "$2000-$3000",
    "$3000-More",
  ];
  const PriceWithoutDollarSigns = Price.map((price) =>
    price.replace(/\$/g, "")
  );

  const handleBrandChange = (brandId, brandName) => {
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(brandId)) {
        return prevSelectedBrands.filter((id) => id !== brandId);
      } else {
        return [...prevSelectedBrands, brandId];
      }
    });
    props.HandleChangeBrand(brandName)
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelectedCategories) => {
      if (prevSelectedCategories.includes(category)) {
        return prevSelectedCategories.filter(
          (selectedCategory) => selectedCategory !== category
        );
      } else {
        return [...prevSelectedCategories, category];
      }
    });
  };

  const handleGenderChange = (gender) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(gender)) {
        return prevSelectedGenders.filter(
          (selectedGender) => selectedGender !== gender
        );
      } else {
        return [...prevSelectedGenders, gender];
      }
    });
  };

  const handleSubCatChange = (subcategory) => {
    setSelectedSubCat((prevSelectedSubCat) => {
      if (prevSelectedSubCat.includes(subcategory)) {
        return prevSelectedSubCat.filter(
          (selectedSubCat) => selectedSubCat !== subcategory
        );
      } else {
        return [...prevSelectedSubCat, subcategory];
      }
    });
  };

  const handlePriceChange = (price) => {
    setSelectedPrices((prevSelectedPrices) => {
      if (prevSelectedPrices.includes(price)) {
        return prevSelectedPrices.filter(
          (selectedPrice) => selectedPrice !== price
        );
      } else {
        return [...prevSelectedPrices, price];
      }
    });
  };

  const [visibleSubcategories, setVisibleSubcategories] = useState([]);
  const toggleSubcategories = (categoryId) => {
    const newVisibleSubcategories = [...visibleSubcategories];
    const index = newVisibleSubcategories.indexOf(categoryId);

    if (index === -1) {
      newVisibleSubcategories.push(categoryId);
    } else {
      newVisibleSubcategories.splice(index, 1);
    }

    setVisibleSubcategories(newVisibleSubcategories);
  };
  const [selectFilter, setSelectedFilter] = useState("");

  const handleSearch = () => {
    navigate(`/products?search=${selectFilter}`); // Navigate to the product page
  };


  const handleClearBtn = () =>{
    setSelectedBrands([])
    setSelectedCategories([])
    setSelectedGenders([])
    setSelectedPrices([])
    setSelectedSubCat([])
    props.resetFunction()
  }

  return (
    <div>
      <div className="shadow" onClick={() => props.setisshown(false)}></div>
      <div className="more-filters-section">
        <div className="filter_header_close">
          <AiOutlineCloseCircle
            size={40}
            className="close_butt"
            onClick={() => props.setisshown(false)}
          />
        </div>
        <div className="filter_header_search">
          <FiSearch
            size={30}
            className="filter_search_icon"
            onClick={() => {
              handleSearch();
            }}
          />
          <input
            type="text"
            placeholder="Search"
            className="filter_search_input"
            value={selectFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          />
        </div>
        <div className="filter_header_title">All Filters</div>

        {selectedBrands.length + selectedCategories.length + selectedGenders.length + selectedPrices.length + selectedSubCat.length !== 0 && ( 
           <div className="clear_all_filters" onClick={handleClearBtn}>
          <button className="clear_all_btn">
            Clear Filter
            <MdClear size={30} className="clear_icon"/>
          </button>
          
        </div>)}


        <div className="filter_header_filters_cont">
          <div
            className="filter_header_filters"
            onClick={() => setbrand(!brand)}
          >
            BRANDS
            {!brand ? (
              <MdKeyboardArrowDown size={30} />
            ) : (
              <MdKeyboardArrowUp size={30} />
            )}
          </div>
          {brand && (
            <div className="filters_cont">
              {brands.map((brand) => (
                <div className="inputs_container" key={brand.id}>
                  <input
                    type="checkbox"
                    name={brand.id}
                    id={brand.id}
                    value={brand.id}
                    className="checkboxss"
                    checked={selectedBrands.includes(brand.id)}
                    onChange={() => handleBrandChange(brand.id, brand.name)}
                  />
                  <label htmlFor={brand.id} className="label_check1">
                    {brand.name}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="filter_header_filters_cont">
          <div
            className="filter_header_filters"
            onClick={() => setproductcat(!productcat)}
          >
            Products
            {!productcat ? (
              <MdKeyboardArrowDown size={30} />
            ) : (
              <MdKeyboardArrowUp size={30} />
            )}
          </div>
          {productcat && (
            <div className="filters_cont">
              {categories.map((category) => (
                <div className="inputs_container" key={category.id}>
                  <div className="testestofinput">
                    <div>
                      <input
                        type="checkbox"
                        name={category.id}
                        id={category.id}
                        value={category.id}
                        className="checkboxss"
                        checked={selectedCategories.includes(category.id)}
                        onChange={() => handleCategoryChange(category.id)}
                      />
                      <label htmlFor={category.id} className="label_check1">
                        {category.name}
                      </label>
                    </div>
                    {category.sub_categories &&
                      category.sub_categories.length > 0 && (
                        <div className="filter_header_filters_cont">
                          <div
                            className="filter_header_filters"
                            onClick={() => toggleSubcategories(category.id)}
                          >
                            {visibleSubcategories.includes(category.id) ? (
                              <MdKeyboardArrowUp size={30} />
                            ) : (
                              <MdKeyboardArrowDown size={30} />
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                  <div>
                    {visibleSubcategories.includes(category.id) && (
                      <div className="filters_cont">
                        {category.sub_categories.map((subCategory) => (
                          <div
                            className="filter_header_filters11111"
                            key={subCategory.id}
                          >
                            <input
                              type="checkbox"
                              name={subCategory.id}
                              id={subCategory.id}
                              value={subCategory.id}
                              className="checkboxss"
                              checked={selectedSubCat.includes(subCategory.id)}
                              onChange={() =>
                                handleSubCatChange(subCategory.id)
                              }
                            />
                            <label
                              htmlFor={subCategory.id}
                              className="label_check1"
                            >
                              {subCategory.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="filter_header_filters_cont">
          <div
            className="filter_header_filters"
            onClick={() => setgender(!gender)}
          >
            Gender
            {!gender ? (
              <MdKeyboardArrowDown size={30} />
            ) : (
              <MdKeyboardArrowUp size={30} />
            )}
          </div>
          {gender && (
            <div className="filters_cont">
              {Gender.map((gender) => (
                <div className="inputs_container" key={gender}>
                  <input
                    type="checkbox"
                    name={gender}
                    id={gender}
                    value={gender}
                    className="checkboxss"
                    checked={selectedGenders.includes(gender)}
                    onChange={() => handleGenderChange(gender)}
                  />
                  <label htmlFor={gender} className="label_check1">
                    {gender}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="filter_header_filters_cont">
          <div
            className="filter_header_filters"
            onClick={() => setprice(!price)}
          >
            Price Range
            {!price ? (
              <MdKeyboardArrowDown size={30} />
            ) : (
              <MdKeyboardArrowUp size={30} />
            )}
          </div>
          {price && (
            <div className="filters_cont">
              {PriceWithoutDollarSigns.map((price) => (
                <div className="inputs_container" key={price}>
                  <input
                    type="checkbox"
                    name={price}
                    id={price}
                    value={price}
                    className="checkboxss"
                    checked={selectedPrices.includes(price)}
                    onChange={() => handlePriceChange(price)}
                  />
                  <label htmlFor={price} className="label_check1">
                    ${price} {/* Add the "$" sign here */}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MoreFiltersSection;
