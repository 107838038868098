import React from "react";
import "./Policy.css";

function Policy() {
  return (
    <>
      <div className="terms_and_conditions_main_container">
        <div className="terms_and_conditions_tite">Privacy & Policy</div>
        <div className="condition">
          Your personal data is very important to Tamer Frères (“we” or “our”).
          This policy (the “Privacy Policy”) is to inform you about the
          processing of personal data that we may collect when you access our
          online and offline services, including the personal data we collect
          through our various channels, such as our websites, applications,
          social networks, our customer relations centers, boutiques, points of
          sale and the events we organize. Tamer Frères and its affiliates are
          committed to protect and respect the privacy of the users of their
          website www.tamer- group.com (“the Site” or “our Site”). This Privacy
          Policy, together with our terms of use, describes the procedures that
          Tamer Frères adopts in order to collect, hold and use information
          about the users who access the Site. Tamer Frères Cookie Policy is a
          supplement to this Privacy Policy and describes what cookies are, what
          Tamer Frères can do with them. By continuing to browse/access the
          Site, you are consenting to our collection and use of information and
          cookies in accordance with this Privacy Policy, which you have read,
          understood and irrevocably agreed to abide by without any oppression
          and without any objection to its content. You agree and acknowledge as
          well that Tamer Frères may at any time change, modify, add, remove or
          update this Privacy Policy wholly or partially at its sole discretion,
          without prior notification. If you disable the cookies that we use,
          this may impact your user experience while on our Site. Your personal
          information (“Personal Data”) will be always handled and used in
          accordance with our Privacy Policy. We invite you to take the time to
          read this Privacy Policy.
          <div className="conditiontime"> Personal Data we Collect</div> The
          term “Personal Data” as used in this Privacy Policy refers to any
          personal data and information, we may collect from you or about you
          such as:
          <ol>
            <li>
              the information you give us (e.g., your name, date of birth, email
              address, mailing address, telephone number etc.) by filling in
              forms on the website or by corresponding with us in any form
              whatsoever;
            </li>
            <li>
              technical information, including without limitation the internet
              protocol (IP) address used to connect your computer to the
              internet, your login information, browser type and version, the
              language preference, the referring site, the date and time of each
              visitor request and the time zone setting, browser plug-in types
              and versions, operating system and platform;
            </li>{" "}
            <li>
              information about your visit, including without limitation the
              full uniform resource locators (URL) clickstream to, through and
              from our website, products you viewed or searched for, page
              response times, download errors, length of visits to certain
              pages, page interaction information (such as scrolling, clicks,
              and mouse-overs), and methods used to browse away from the page;
            </li>{" "}
            <li>
              any other information relating to you collected or generated or in
              respect of your use of or visits to the website.
            </li>{" "}
            <li>
              any other information that may be needed in the future if we
              developed our website, whether our privacy policy has been
              modified or not. We collect non-personally- identifying
              information of the sort that web browsers and servers typically
              make available, such as. Our purpose in collecting non-personally
              identifying information is to better understand how visitors use
              our website. We also collect potentially personally- identifying
              information like Internet Protocol (IP) addresses
            </li>{" "}
          </ol>{" "}
          <div className="conditiontime">What is a Cookie?</div>A Cookie is a
          small text file that is stored on the hard drive of your computer or
          other electronic device (“Device”) used to access our website(s),
          mobile application(s) and any other online media or electronic
          communication service that we operate (“Services”). Your web browser
          stores information on your Device regarding your visit to and use of
          our Services for record- keeping purposes (e.g. logging in) or track
          information. We use Cookies to improve your user experience, monitor
          statistics and optimize advertising campaigns when you use our
          Services. You can choose whether to accept or decline Cookies by
          changing the settings directly on your web browser. We do not use
          Cookies to collect personal information such as your name; however,
          Cookies might help us relate information to the personal information
          you provided us through other means (e.g., general website
          registration).
          <div className="conditiontime">
            {" "}
            Use of your Personal Data and Cookies
          </div>{" "}
          Personal Data will be used mainly to provide you with personalized
          communication and to market Tamer Frères products and services which
          we think may be of interest to you. We use cookies to help us identify
          and track visitors, their usage of our website, their website access
          preferences, and to measure and review the effectiveness of our
          interactive online content, its characteristics, advertising and other
          communication and help us run our Platform more efficiently. Our
          website uses cookies also to distinguish you from other users of our
          website. Cookies may also be used to help speed up your future
          activities and experience on our website. We collect and use your
          personal data to process your orders, purchases and deliveries. And
          also to answer your queries, questions and/or requests of any kind. We
          cannot identify you personally from this information and this is not
          the purpose of the Personal data and Cookies use. This use helps us to
          provide you with a good experience when you browse our website and
          also allows us to improve our Site. Please note that third parties
          (including, for example, advertising networks and providers of
          external services like web traffic analysis services) may also use
          cookies, over which we have no control. These cookies are likely to be
          analytical/performance cookies or targeting cookies Cookies help us
          improve our customer service and managing our relationship with you.
          Cookies are mandatory to navigate this website and securely access our
          services, while enhancing your user experience.
          <div className="conditiontime">
            The Disclosure of the Personal Data
          </div>
          We will not publish, share, sell, or distribute your Personal Data to
          third parties except as provided in this Privacy Policy. You hereby
          irrevocably agree and consent that Personal Data may be transferred to
          third parties who act for or on our behalf, for further processing in
          accordance with the purpose for which the Personal Data was originally
          collected or may otherwise be lawfully processed, such as service
          delivery, marketing, data management or technical support. As for
          example and not limitation, we may share Personal Data with our group
          of companies and affiliated companies, agencies who help us collate
          statistics about site traffic, sales, demographics and other
          commercial information to enable us to adapt the services we provide
          to you and to all our customers, governmental bodies, our suppliers,
          because of any applicable laws, court order or governmental
          regulation, or if such disclosure is otherwise necessary in support of
          any criminal or other legal investigation or proceedings in accordance
          with any applicable laws and regulations.
          <div className="conditiontime">
            {" "}
            And if you use External websites?{" "}
          </div>
          Some of the links on our website may lead you to external websites of
          our partners, advertisers or suppliers, which are not operated by
          Tamer Frères. Such external websites will be governed by their own
          privacy policies and terms which Tamer Frères accepts no
          responsibility or liability for, under any circumstance.
          <div className="conditiontime">
            {" "}
            Do we guarantee the Security of the Personal Data?{" "}
          </div>{" "}
          Whenever we process Personal Data, we take reasonable steps to ensure
          that your Personal Data is protected. In the course of handling your
          Personal Data, we take measures reasonably designed to protect that
          information from loss, misuse, unauthorized access, disclosure,
          alteration or destruction. Nevertheless, unfortunately, the
          transmission of information via the internet is not completely secure.
          Although we will take reasonable measures to protect your Personal
          Data, we cannot guarantee the security of your data transmitted to our
          website; any transmission is at your full own risk and Tamer Frères
          accepts no responsibility or
          <div className="conditiontime">
            Amendments to our Privacy Policy
          </div>{" "}
          The We reserve the right to change this Privacy Notice at any time. We
          encourage you to check this page periodically for updates and to stay
          informed about the steps we take to protect the personal data we
          collect. You agree and acknowledge that it is your responsibility to
          regularly review our Sites to be aware of any changes made thereto.
          <div className="conditiontime"> Contact Us</div>If you have any
          questions about this Privacy Notice, please contact us at info@tamer-
          group.com
        </div>
      </div>
    </>
  );
}

export default Policy;
