import React from "react";
import "./PreviousOrders.css";
import "../../Pages/Profile/Profile.css";
import { useNavigate } from "react-router-dom";

function PreviousOrders(props) {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div className="order_card">
        <div className="head_cont">
          <div className="order_img_cont">
            {props.image1 && <img src={ props.image1} className="pre_orders_img" />}
            {props.image2 && <img src={props.image2} className="pre_orders_img" />}
          </div>
          <div className="prev_order_numbers">+{props.rest} ITEMS</div>
        </div>
        <div className="prev_orders_inf">
          <div className="order_num">{"Order #" + props.order}</div>
          <div className="order_date">{props.date}</div>
          <div className="order_price">{props.method}</div>
        </div>
        <div className="prev_orders_inf2">
          <button className="button_1"  onClick={() => navigate("/track_order_status/" + props.order)}>VIEW</button>
        </div>
      </div>
    </div>
  );
}

export default PreviousOrders;
