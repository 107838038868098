import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Category: [],
  productpre: [],
  productprecat: [],
  Categoryproductpre: [],
  oneproduct: [],
  oneproductpre: [],
  products: [],
  featureprod: [],
  loading: false,
  error: null,
};

const ProductsSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    CategoryRequest(state, action) {
      state.loading = true;
    },
    CategorySuccess(state, action) {
      state.loading = false;
      state.Category = action.payload;
    },
    CategoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    productpreRequest(state, action) {
      state.loading = true;
    },
    productpreSuccess(state, action) {
      state.loading = false;
      state.productpre = action.payload;
    },
    productpreFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    oneproductRequest(state, action) {
      state.loading = true;
    },
    oneproductSuccess(state, action) {
      state.loading = false;
      state.oneproduct = action.payload;
    },
    oneproductFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    ProductsRequest(state, action) {
      state.loading = true;
    },
    ProductsSuccess(state, action) {
      state.loading = false;
      state.products = action.payload;
    },
    ProductsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    featureprodRequest(state, action) {
      state.loading = true;
    },
    featureprodSuccess(state, action) {
      state.loading = false;
      state.featureprod = action.payload;
    },
    featureprodFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    oneproductpreRequest(state, action) {
      state.loading = true;
      state.oneproductpre = [];
    },
    oneproductpreSuccess(state, action) {
      state.loading = false;
      state.oneproductpre = action.payload;
    },
    oneproductpreFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.oneproductpre = [];
    },

    CategoryproductpreRequest(state, action) {
      state.loading = true;
      state.Categoryproductpre = [];
      state.error = null;
    },
    CategoryproductpreSuccess(state, action) {
      state.loading = false;
      state.Categoryproductpre = action.payload;
      state.error = null;
    },
    CategoryproductpreFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.Categoryproductpre = [];
    },
  },
});

export const ProductsAction = ProductsSlice.actions;
export default ProductsSlice;
