import React, { useState } from "react";
import "./WatchFavorite.css";
import pic from "./../../assets/Images/Cart/IMAGE.png";
import { BsFillHeartFill } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import {
  getFavorite,
  deletefavorite,
} from "./../../Redux/homepage/HomePageAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function WatchFavorite(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  const handleFavorite = () => {
    dispatch(deletefavorite(props.id));
    // dispatch(getFavorite());
  };


  const handleFavoritelink = () => {
    navigate(`/product/productdetails/${props.linkto}`);
  };

  return (
    <div className="watchcardfavorite" onClick={handleFavoritelink}>
      <div className="pocfav">
        <img src={props?.img} alt="" className="pocfav" />
      </div>
      <div className="watchcardfavorite__info">
        <div className="pocfav1">
          <div className="watchcardfavorite__info__name">{props.name}</div>
          <div
            className="watchcardfavorite__info__brand1"
            onClick={handleFavorite}
          >
            <BsFillHeartFill size={95} className="heart" />
          </div>
        </div>

        <div className="watchcardfavorite__info__brand">
          {props.description !== "NULL" ? <>{props.description}</> : <>&nbsp; </>}
        </div>
        <div className="watchcardfavorite__info__price">{props.description !== "NULL" ? <>{props.price}</> : <>&nbsp; </>}</div>
      </div>
    </div>
  );
}

export default WatchFavorite;
