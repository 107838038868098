import { React, useState, useEffect } from "react";
import MoreFiltersSection from "./MoreFiltersSection";
import { IoIosArrowDown } from "react-icons/io";
import "./Filter.css";
import { useDispatch, useSelector } from "react-redux";
import { GetBrands } from "../../Redux/Brand/BrandActions";
import { GetCategories } from "../../Redux/Category/CategoryActions";
import { GetAllProducts } from "../../Redux/Products/ProductsAction";
import { SettingsAction } from "../../Redux/settingsRedux";
import { IoCloseOutline } from "react-icons/io5";

function Filter(props) {
  const dispatch = useDispatch();
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [showbrands, setshowbrands] = useState(false);
  const [showproducts, setshowproducts] = useState(false);
  const [showgenders, setshowgenders] = useState(false);
  const [selectedBrandsFilters, setSelectedBrandsFilters] = useState("");
  const [selectedProductsFilters, setSelectedProductsFilters] = useState("");
  const [selectedGenderFilters, setSelectedGenderFilters] = useState("");
  const [selectedSortByFilters, setSelectedSortByFilters] = useState("");
  const { isReset } = useSelector(state => state.SettingsReducer)



  const [moreFiltersClicked, setMoreFiltersClicked] = useState(false);

 
  const { brands } = useSelector((Store) => Store.BrandReducer);
  const { categories } = useSelector((Store) => Store.CategoryReducer);
  const [sortby, setsortby] = useState(false);
  const limit = 16;
  const [activePage, setActivePage] = useState(1);
  const Gender = ["Man", "Woman", "Kids", "Man|Woman"];
  const filters = [
    {
      label: "Sort By Price : low to high",
      value: "price-asc",
    },
    {
      label: "Sort By Price : high to low",
      value: "price-desc",
    },

    {
      label: "Sort by Best Seller",
      value: "best_seller",
    },
  ];
  const [selectFilter, setSelectFilter] = useState("");

  const getFilteredProductsData = (filter) => {
    setSelectFilter(filter);
    dispatch(GetAllProducts(limit, activePage, filter));
  };

  const RemoveQueryString = () => {
      const removeQueryString = () => {
        const baseURL = window.location.href.split('?')[0];
        window.history.replaceState({}, document.title, baseURL);
      };
  
      removeQueryString();
  };

  useEffect(() => {
    if(showMoreFilters && window.innerWidth > 769){
      document.body.style.marginLeft="20rem"
    }else{
      document.body.style.marginLeft="0rem"
    }
  },[showMoreFilters])
  useEffect(() => {
    dispatch(GetAllProducts(limit, activePage, selectFilter));
    // if(window.location.search === ""){
    //   setIsEmpty(false)
    // }else{
    //   setIsEmpty(true)
    // }
  }, []);

  useEffect(() => {
    dispatch(GetBrands());
    dispatch(GetCategories());
  }, [dispatch]);

  useEffect(() => {
    if (props.isopen === false) {
      setshowbrands(false);
      setshowproducts(false);
      setshowgenders(false);
      setsortby(false);
    }

    if (isReset === true) {
      setSelectedBrandsFilters("")
      setSelectedProductsFilters([])
      setSelectedGenderFilters("")
      setSelectedSortByFilters("")
    }


  }, [isReset, props.isopen, showbrands])

  useEffect(() => {
    const brandAsInteger = parseInt(props.brand, 10);
    const categoryAsInteger = parseInt(props.category, 10);
    if (props.brand) setSelectedBrandsFilters([brandAsInteger]);
    if (props.category) setSelectedProductsFilters([categoryAsInteger]);
  }, [props.brand, props.category]);

  useEffect(() => {
    let filtersString = "";
    if (selectedBrandsFilters.length > 0) {
      filtersString += "brand=" + selectedBrandsFilters + "&";
    }
    if (selectedProductsFilters.length > 0) {
      filtersString += "category=" + selectedProductsFilters + "&";
    }
    if (selectedGenderFilters.length > 0) {
      filtersString += "gender=" + selectedGenderFilters + "&";
    }
    if (selectedSortByFilters.length > 0) {
      filtersString += "sort=" + selectedSortByFilters + "&";
    }
    if (filtersString.length > 0) props.getFilteredProducts(filtersString);
  }, [
    selectedBrandsFilters,
    selectedProductsFilters,
    selectedGenderFilters,
    selectedSortByFilters,
  ]);

  function checkIfIdExists(mainArray, idSelected) {
    return mainArray.includes(idSelected);
  }

  const handleBrandCheckboxChange = (brandIndex, brandName) => {
    // setIsEmpty(true)
    dispatch(SettingsAction.resetFalse())
    const index = selectedBrandsFilters.indexOf(brandIndex);
    props.handleChangeBrand(brandName)
    if (index !== -1) {
      // Remove the brand index from selectedBrandsFilters
      const updatedFilters = [...selectedBrandsFilters];
      updatedFilters.splice(index, 1);
      setSelectedBrandsFilters(updatedFilters);
    } else {
      // Add the brand index to selectedBrandsFilters
      setSelectedBrandsFilters([...selectedBrandsFilters, brandIndex]);
    }
  };

  const handleProductsCheckboxChange = (productIndex) => {
    dispatch(SettingsAction.resetFalse())
    // setIsEmpty(true)

    const index = selectedProductsFilters.indexOf(productIndex);
    if (index !== -1) {
      // Remove the product index from selectedProductsFilters
      const updatedFilters = [...selectedProductsFilters];
      updatedFilters.splice(index, 1);
      setSelectedProductsFilters(updatedFilters);
    } else {
      // Add the product index to selectedProductsFilters
      setSelectedProductsFilters([...selectedProductsFilters, productIndex]);
    }
  };

  const handleGenderCheckboxChange = (genderIndex) => {
    dispatch(SettingsAction.resetFalse())
    // setIsEmpty(true)

    const index = selectedGenderFilters.indexOf(genderIndex);
    if (index !== -1) {
      // Remove the product index from selectedGenderFilters
      const updatedFilters = [...selectedGenderFilters];
      updatedFilters.splice(index, 1);
      setSelectedGenderFilters(updatedFilters);
    } else {
      // Add the product index to selectedGenderFilters
      setSelectedGenderFilters([...selectedGenderFilters, genderIndex]);
    }
  };

  const handleSortByCheckboxChange = (sort) => {
    dispatch(SettingsAction.resetFalse())
    if (selectedSortByFilters.includes(sort)) {
      const updatedFilters = selectedSortByFilters
        .split(",")
        .filter((item) => item !== sort)
        .join(",");
      setSelectedSortByFilters(updatedFilters);
    } else {
      setSelectedSortByFilters(
        selectedSortByFilters ? `${selectedSortByFilters},${sort}` : sort
      );
    }
  };

  function handleMoreFiltersClick() {
    setShowMoreFilters(!showMoreFilters);
    setMoreFiltersClicked(!moreFiltersClicked);
  }

  const handlereset = () => {
    setSelectedBrandsFilters("")
    setSelectedProductsFilters([])
    setSelectedGenderFilters("")
    setSelectedSortByFilters("")
    setshowbrands(null);
    setshowproducts(null);
    setshowgenders(null);
    setsortby(null);
    dispatch(SettingsAction.resetFalse())
    dispatch(SettingsAction.reset())
    getFilteredProductsData('')
    props.handleChangeBrand("")
    RemoveQueryString()
    // window.location.replace("/products")
  };

  return (
    <div className={`Main_filter ${moreFiltersClicked ? 'more-filters-clicked' : ''}`}>
      <div className="filter-section">
        {showMoreFilters ? (
          // MoreFiltersSection is shown, so don't render filter-row
          null
        ) : (
          <div className="filter-row filter-row-custom">
            <div
              className="filter-item"
              onClick={() => {
                setshowbrands(!showbrands);
                setshowproducts(false);
                setshowgenders(false);
                setsortby(false);
                props.setIsopen(!props.isopen)

              }}
            >
              <div className="filter_container_title"> Brands </div>
              <div className="filter_arrow">
                <IoIosArrowDown
                  size={20}
                  className={showbrands ? "arrow_down" : "arrow_up"}
                />
              </div>
              {showbrands && (
                <div className="Main_filters_card designFix">
                  {brands.map((brand, index) => (
                    <div key={index} className="inputs_container">
                      <input
                        type="checkbox"
                        name={brand?.name}
                        id={brand?.id}
                        value={brand?.name}
                        className="checkbox"
                        onChange={() => handleBrandCheckboxChange(brand?.id,brand?.name)}
                        checked={checkIfIdExists(selectedBrandsFilters, brand?.id)}
                      />
                      <label htmlFor={brand?.id} className="label_check">
                        {brand?.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className="filter-item"
              onClick={() => {
                setshowbrands(false);
                setshowproducts(!showproducts);
                setshowgenders(false);
                setsortby(false);
                props.setIsopen(!props.isopen)

              }}
            >
              <div className="filter_container_title"> Products </div>
              <div className="filter_arrow">
                <IoIosArrowDown
                  size={20}
                  className={showproducts ? "arrow_down" : "arrow_up"}
                />
              </div>
              {showproducts && (
                <div className="Main_filters_card designFix">
                  {categories.map((product, index) => (
                    <div key={index} className="inputs_container">
                      <input
                        type="checkbox"
                        name={product?.name}
                        id={product?.id}
                        value={product?.name}
                        className="checkbox"
                        onChange={() => handleProductsCheckboxChange(product?.id)}
                        checked={checkIfIdExists(
                          selectedProductsFilters,
                          product?.id
                        )}
                      />
                      <label htmlFor={product?.id} className="label_check">
                        {product?.name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className="filter-item"
              onClick={() => {
                setshowbrands(false);
                setshowproducts(false);
                setshowgenders(!showgenders);
                setsortby(false);
                props.setIsopen(!props.isopen)


              }}
            >
              <div className="filter_container_title"> Genders </div>
              <div className="filter_arrow">
                <IoIosArrowDown
                  size={20}
                  className={showgenders ? "arrow_down" : "arrow_up"}
                />
              </div>
              {showgenders && (
                <div className="Main_filters_card designFix"> {/* current classes "Main_filters_card1 dropdown-gender designFix" */}
                  {Gender.map((gender, index) => (
                    <div key={index} className="inputs_container">
                      <input
                        type="checkbox"
                        name={gender}
                        id={gender}
                        value={gender}
                        className="checkbox"
                        onChange={() => handleGenderCheckboxChange(gender)}
                        checked={checkIfIdExists(selectedGenderFilters, gender)}
                      />
                      <label htmlFor={gender} className="label_check">
                        {gender}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>


            <div
              className="filter-item"
              onClick={() => {
                setshowbrands(false);
                setshowproducts(false);
                setshowgenders(false);
                setsortby(!sortby);
                props.setIsopen(!props.isopen)

              }}
            >
              <div className="filter_container_title"> Sort By </div>
              <div className="filter_arrow">
                <IoIosArrowDown
                  size={20}
                  className={sortby ? "arrow_down" : "arrow_up"}
                />
              </div>
              {sortby && (
                <div className="Main_filters_card designFix">
                  {filters.map((filter, index) => (
                    <div key={index} className="inputs_container">
                      <input
                        type="radio"
                        name="radioGroup" // Give all radio buttons in the group the same name
                        id={filter.value}
                        value={filter.value}
                        className="checkbox"
                        onChange={() => handleSortByCheckboxChange(filter.value)}
                        checked={checkIfIdExists(
                          selectedSortByFilters,
                          filter.value
                        )}
                      />

                      <label htmlFor={filter.value} className="label_check">
                        {filter.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>



            <div className="filter-item">
              <button
                className="filters_more_button"
                onClick={handleMoreFiltersClick}
              >
                More filters
              </button>
            </div>


            {selectedBrandsFilters.length + selectedGenderFilters.length + selectedProductsFilters.length + selectedSortByFilters.length > 0 && 
            <div className="title_drop_cont" onClick={handlereset}>
              <div className="trash_cont">
                <span>Clear</span>
                <IoCloseOutline className="trash_icon" />
              </div>
            </div>}


          </div>
        )}
        {showMoreFilters && (
          <MoreFiltersSection
            isShown={showMoreFilters}
            setisshown={setShowMoreFilters}
            getFilteredProducts={getFilteredProductsData}
            resetFunction={handlereset}
            HandleChangeBrand={props.handleChangeBrand}
          />
        )}
      </div>
      {/* <div className="filter-section2">
        <div className="filter-row">
          <div className="filter-item" onClick={() => setsortby(!sortby)}>
            <div className="filter_container_title"> Genders </div>
            <div className="filter_arrow">
              <IoIosArrowDown
                size={20}
                className={sortby ? "arrow_down" : "arrow_up"}
              />
            </div>
          </div>
          {sortby && (
            <div className="Main_filters_card1">
              {Gender.map((brand) => (
                <div className="inputs_container">
                  <input
                    type="checkbox"
                    name={brand}
                    id={brand}
                    value={brand}
                    className="checkbox"
                  />
                  <label htmlFor={brand} className="label_check">
                    {brand}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default Filter;
