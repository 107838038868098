import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brands: [],
  brand:[],
  loading: false,
  error: null,
};

const BrandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    BrandRequest(state, action) {
      state.loading = true;
      state.brands = [];
      state.error = null;
    },
    BrandSuccess(state, action) {
      state.loading = false;
      state.brands = action.payload;
      state.error = null;
    },
    BrandFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.brands = [];
    },
    OneBrandRequest(state, action) {
      state.loading = true;
      state.brand = [];
      state.error = null;
    },
    OneBrandSuccess(state, action) {
      state.loading = false;
      state.brand = action.payload;
      state.error = null;
    },
    OneBrandFail(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.brand = [];
    },

  },
});

export const BrandAction = BrandSlice.actions;
export default BrandSlice;
