import React, { useEffect, useState } from "react";
import WatchesViewCard from "./../../Components/WatchesViewCard/WatchesViewCard";
import image from "./../../assets/Images/Cart/IMAGE.png";
import "./PreOrder.css";

import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  getcategory,
  GetAllPreoProducts,
} from "./../../Redux/PreOrder/PreOrderAction";
import Loader from "./../../Components/Loader/Loader";

function PreOrder() {
  const [sortby, setsortby] = useState(false);

  const filters = [
    {
      label: "Sort By Price : low to high",
      value: "price-asc",
    },
    {
      label: "Sort By Price : high to low",
      value: "price-desc",
    },
    {
      label: "Random",
      value: "random",
    },
    {
      label: "Sort by availability",
      value: "quantity_limit",
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");

  useEffect(() => {
    dispatch(getcategory());
    dispatch(GetAllPreoProducts());
  }, [dispatch]);
  const { ProCategory, PreProduct, loading } = useSelector(
    (state) => state.PreoReducer
  );

  const [record, setRecord] = useState([]);
  const [records, setRecords] = useState([]);
  useEffect(() => {
    setRecord(ProCategory?.data?.data);
  }, [ProCategory]);
  useEffect(() => {
    setRecords(PreProduct?.data);
  }, [PreProduct]);

  // useEffect(() => {
  //   setRecords(productpre?.data?.data);
  // }, [productpre]);

  const [activePage, setActivePage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const handlePageChange = (pageNum) => { };

  const handleFilterChange = (filter) => { };
  return (
    <div>
      {/* */}
      <div className="starter">
        <div className="upper">
          <div className="rout">
            <div className="homerouting">
              {" "}
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                {" "}
                HOME &nbsp;&nbsp;{" "}
              </Link>
              /
            </div>
            <div className="Proute"> &nbsp;&nbsp; PRE-O</div>
          </div>
          <div className="Pro">PRE-O</div>
        </div>
        <div className="down">
          {record?.map((category, index) => (
            <div
              className="cat"
              onClick={() => {
                navigate(`/categorypre/${category?.id}`);
              }}
              key={index}
            >
              {category?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="fsort">
        <div className="filter-row">
          <div className="filter-item" onClick={() => setsortby(!sortby)}>
            <div className="filter_container_title"> Sort By </div>
            <div className="filter_arrow">
              <IoIosArrowDown
                size={20}
                className={sortby ? "arrow_down" : "arrow_up"}
              />
            </div>
            {sortby && (
              <div className="Main_filters_card2">
                {filters.map((filter, index) => (
                  <div key={index} className="inputs_container">
                    <input
                      type="checkbox"
                      name={filter.value}
                      id={filter.value}
                      value={filter.value}
                      className="checkbox"
                      onClick={() => handleFilterChange(filter.value)}
                    />
                    <label htmlFor={filter.value} className="label_check">
                      {filter.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>


          <div className="filter-item">
            <div className="filter_container_title">
              {" "}
              Showing {records?.length}- Of Results{" "}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="product">
          {records?.map((product, index) => (
            <WatchesViewCard
              key={index}
              image={product?.img_url}
              brand={product?.brand?.name}
              name={product?.title}
              link={product?.id}
              type="Pre-o"
            />
          ))}
        </div>
      )}
      <div className="Products_container_pagination">
        <div className="pagination_container">
          {Array.from({ length: records?.totalPages }, (_, i) => i + 1).map(
            (pageNum) => (
              <span
                key={pageNum}
                className={`pagination_container_item ${pageNum === activePage
                  ? "pagination_container_item_active"
                  : ""
                  }`}
                onClick={() => handlePageChange(pageNum)}
              >
                {pageNum}
              </span>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default PreOrder;
