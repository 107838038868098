import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isReset: false,
};

const SettingsSlice = createSlice({
    name: "Settings",
    initialState,
    reducers: {
        reset: (state) => {
            state.isReset = true;
        },
        resetFalse: (state) => {
            state.isReset = false;
        }
    },
});

export const SettingsAction = SettingsSlice.actions;
export default SettingsSlice;
