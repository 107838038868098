import React from "react";
import "./TrackOrder.css";

function TrackOrder() {
  return (
    <>
      <div className="track_order_container_main">
        <div className="track_order_container">
          <div className="track_order_title">Track your order</div>
          <div className="input_label">USERNAME OR EMAIL ADDRESS *</div>
          <input
            type="text"
            placeholder="Enter your email"
            className="email_input"
          />

          <button className="login_btn">login</button>
          <div className="contact_support">Having Trouble? Contact Support</div>
        </div>
      </div>
    </>
  );
}

export default TrackOrder;
