import jwt_decode from "jwt-decode";
import { userAction } from "../Redux/Login/Register/LoginReducers";
import setAuthToken from "./setAuthToken";

const isAuth = (dispatch) => {
  const token = localStorage.getItem("token");

  if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      dispatch(userAction.Logout());
      setAuthToken(false);
      return false;
    }
    setAuthToken(token);
    return true;
  } else {
    return false;
  }
};

export default isAuth;
