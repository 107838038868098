import React, { useEffect, useState } from "react";
import "./TrackOrderStatus.css";
import { useParams } from "react-router-dom";
import { GetOrderByNumber } from "../../Redux/Order/OrderActions";
import { useDispatch, useSelector } from "react-redux";

function TrackOrderStatus() {
  const { order_number } = useParams();
  const { order } = useSelector((Store) => Store.OrderReducer);
  const dispatch = useDispatch();
  const [orederData, setOrederData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);

  const [deliveryCharge, setDeliveryCharge] = useState(5.0);

  useEffect(() => {
    dispatch(GetOrderByNumber(order_number));
  }, []);

  useEffect(() => {
    if (order) {
      let subTot = 0;
      order.map((product, orderIndex) => {
        subTot = subTot + product?.sub_total;
      });
      setSubTotal(subTot);
      setOrederData(order);
    }
  }, [order]);


  return (
    <>
      <div className="track_order_status_container_main">
        <div className="track_order_status_container">
          <div className="track_order_title">Your order is </div>
          <div className="track_order_title track_status">processing</div>
          <div className="track_order_numb">#{order_number}</div>
          <div className="order_details">order details</div>
          <div className="order_details_data">
            <div className="order_details_div1">
              <table className="order_details_table">
                <tr className="order_details_table_header">
                  <td>Product</td>
                  <td>price</td>
                  <td>quantity</td>
                  <td>subtotal</td>
                </tr>
                {orederData.map((product, orderIndex) => (
                  <tr key={orderIndex} className="order_details_table_data">
                    <td>
                      <img
                        src={product?.product?.img_url}
                        alt="image"
                        className="track_order_img"
                        draggable={false}
                      />
                      <div className="product_details">
                        {product?.product?.description?.specification}
                      </div>
                    </td>
                    <td>{product?.product?.price}</td>
                    <td>{product?.quantity}</td>
                    <td>{product?.sub_total}</td>
                  </tr>
                ))}
              </table>
            </div>
            <div className="order_details_div2">
              <div className="cart_total_title">Cart total</div>
              <div className="cart_total_subtitle">Subtotal</div>
              <div className="track_order_numb">{"$" + subTotal}</div>
              <div className="horizontal_divider"></div>
              <div className="cart_total_subtitle">Shipping</div>
              <div className="track_order_numb">
                {"Delivery Charge: $" + deliveryCharge}
              </div>
              <div className="horizontal_divider"></div>
              <div className="cart_total_subtitle">Total</div>
              <div className="cart_total_number">
                {"$" + (subTotal + deliveryCharge)}
              </div>
            </div>
          </div>
          <div className="address_div">
            <div className="order_details">address</div>
            <div className="address_div_titles">billing</div>
            <div className="order_details_table_data">
              {orederData[0]?.billing?.building_name} ,{" "}
              {orederData[0]?.billing?.street_address} ,{" "}
              {orederData[0]?.billing?.region} , {orederData[0]?.billing?.city},{" "}
              {orederData[0]?.billing?.country}
            </div>
            <div className="address_div_titles">shipping</div>
            <div className="order_details_table_data">
              {orederData[0]?.shipping?.building_name} ,{" "}
              {orederData[0]?.shipping?.street_address} ,{" "}
              {orederData[0]?.shipping?.region} ,{" "}
              {orederData[0]?.shipping?.city},{" "}
              {orederData[0]?.shipping?.country}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackOrderStatus;
