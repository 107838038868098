import axios from "axios";
import { HomeAction } from "./HomePageReducer";

export const getVideo = () => async (dispatch) => {
  try {
    dispatch(HomeAction.videoRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/video/1");
    dispatch(HomeAction.videoSuccess(data));
  } catch (error) {
    dispatch(HomeAction.videoFail(error));
  }
};

export const getFavorite = (sortBy) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(HomeAction.favoriteRequest());
    const data = await axios.get(
      `${process.env.REACT_APP_API}/favorite/all/token${
        sortBy ? `?sortBy=${sortBy}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(HomeAction.favoriteSuccess(data));
  } catch (error) {
    dispatch(HomeAction.favoriteFail(error));
  }
};
export const movetofavorite = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  try {
    dispatch(HomeAction.movetofavoriteRequest());
    const data = await axios.post(
      process.env.REACT_APP_API + "/card/moveToWishList/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(HomeAction.movetofavoriteSuccess(data));
  } catch (error) {
    dispatch(HomeAction.movetofavoriteFail(error));
  }
};
export const deletefavorite = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    dispatch(HomeAction.deletefavoriteRequest());
    const data = await axios.delete(
      process.env.REACT_APP_API + "/favorite/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(HomeAction.deletefavoriteSuccess(data));
    dispatch(getFavorite());
  } catch (error) {
    dispatch(HomeAction.deletefavoriteFail(error));
  }
};
export const addfavorite = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    dispatch(HomeAction.addfavoriteRequest());
    const data = await axios.post(
      process.env.REACT_APP_API + "/favorite/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(HomeAction.addfavoriteSuccess(data));
    dispatch(getFavorite());
  } catch (error) {
    dispatch(HomeAction.addfavoriteFail(error));
  }
};
export const getPartner = () => async (dispatch) => {
  try {
    dispatch(HomeAction.ourPartnerRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/partner/All");
    dispatch(HomeAction.ourPartnerSuccess(data?.data?.data));
  } catch (error) {
    dispatch(HomeAction.ourPartnerFail(error));
  }
};

export const getfeature = () => async (dispatch) => {
  try {
    dispatch(HomeAction.featureRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/featured_product"
    );
    dispatch(HomeAction.featureSuccess(data?.data?.data));
  } catch (error) {
    dispatch(HomeAction.featureFail(error));
  }
};
export const getStory = () => async (dispatch) => {
  try {
    dispatch(HomeAction.storyRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/news/All");
    dispatch(HomeAction.storySuccess(data?.data));
  } catch (error) {
    dispatch(HomeAction.storyFail(error));
  }
};
export const getbrand = () => async (dispatch) => {
  try {
    dispatch(HomeAction.brandfeatureRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/featured_brand");
    dispatch(HomeAction.brandfeatureSuccess(data?.data));
  } catch (error) {
    dispatch(HomeAction.brandfeatureFail(error));
  }
};

export const getonestory = (id) => async (dispatch) => {
  try {
    dispatch(HomeAction.onestoryRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/news/" + id);
    dispatch(HomeAction.onestorySuccess(data?.data));
  } catch (error) {
    dispatch(HomeAction.onestoryFail(error));
  }
};
export const bookapp =
  (firstName, lastName, comment, appointmentDate, phoneNumber) =>
  async (dispatch) => {
    try {
      dispatch(HomeAction.makeappRequest());
      const data = await axios.post(
        process.env.REACT_APP_API + "/BookAppointment",
        {
          firstName,
          lastName,
          comment,
          appointmentDate,
          phoneNumber,
        }
      );
      dispatch(HomeAction.makeappSuccess(data));
    } catch (error) {
      dispatch(HomeAction.makeappFail(error));
    }
  };
export const preoOrder =
  (id, firstName, lastName, special_request, email, phoneNumber) =>
  async (dispatch) => {
    try {
      dispatch(HomeAction.makeappRequest());
      const data = await axios.post(
        process.env.REACT_APP_API + "/request/" + id,
        {
          firstName,
          lastName,
          special_request,
          email,
          phoneNumber,
        }
      );
      dispatch(HomeAction.makeappSuccess(data));
    } catch (error) {
      dispatch(HomeAction.makeappFail(error));
    }
  };

export const getBestSeller = (cat) => async (dispatch) => {
  try {
    dispatch(HomeAction.bestSellerRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/product/bestSeller?cat=" + cat
    );

    dispatch(HomeAction.bestSellerSuccess(data?.data));
  } catch (error) {
    dispatch(HomeAction.bestSellerFail(error));
  }
};
