import React from "react";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <>
      <div className="terms_and_conditions_main_container">
        <div className="terms_and_conditions_tite">Terms & Conditions</div>
        <div className="condition">
          <div className="conditiontime">Preamble</div>
          The website www.tamer-group.com (the &quot;Site&quot;) is operated by
          Tamer Frères (&quot;Tamer Frères&quot;, “we”, “us”, which such terms
          may include our affiliates, sister companies, agents and suppliers
          from time to time and when applicable). By accessing, browsing and
          using the Site, you unconditionally accept the Terms of Use.
          Similarly, by using the Site, you acknowledge Tamer Frères’ Privacy
          Statement and Cookie Policy. You acknowledge that these Terms of Use
          supersede any other agreement between you and Tamer Frères SAL or any
          of its affiliates/sister companies. Please feel free to browse this
          Site. Your access and use of the information contained herein is
          subject to the following terms and conditions and all applicable laws.
          <div className="conditiontime"> Terms of Site use</div> These terms of
          use along with all the documents referred to in it inform you (“you”
          means any website’s users. “User” means any individual/entity that
          uses the website under any form whatsoever, including without
          limitation accessing, visiting, browsing, registering, logging to the
          website). These terms of use engulf any website affiliated to our Site{" "}
          <div className="conditiontime">Changes to the Terms</div> We may
          revise these terms of use at any time by amending this page without
          any prior notice. Please check this page from time to time to be
          posted regarding any changes made, as they are automatically binding
          and applicable on you.
          <div className="conditiontime">
            {" "}
            Copyright and Intellectual Property{" "}
          </div>
          Tamer Frères is the owner of the Site. The content of the Site and in
          particular the texts, trademarks, logos, graphics, photographs,
          videos, illustrations, wallpapers, music, layouts, design, know-how,
          technology, software, products and processes (the “Content”) are the
          property of Tamer Frères or its affiliates or are used with the
          authorization of the rights holders and are, as such, protected by
          their copyright, trademarks, designs, patents and any other applicable
          intellectual property rights. You should not change or delete or
          duplicate any copyright, trademark or other proprietary notices. You
          should not as well copy/use/duplicate these Terms of Use. If you
          duplicate copy or download any part of our Site in breach of these
          terms of use, your right to use our Site will cease immediately
          without need to any notice and you must, at our full discretion,
          return or destroy any copies of the materials you have made at your
          own cost. You are not permitted as well to provide links to our Site
          on any other Site, without our prior written consent. You must not use
          any part of the content on our Site for commercial or advertising
          purposes without our express prior written consent and before
          obtaining a license to do so either directly from us or through us
          from our licensors. Certain other trademarks used on our Site are the
          property of third parties and have been used by Tamer Frères under
          license or other arrangements or understandings. No use of these
          trademarks may be made by you without our express written consent or
          you will be in breach to these Terms of Use as well as to the laws and
          regulations under which those third parties are working. We may update
          our Site from time to time, and may change the content at any time
          without prior notice. Tamer Frères Products All the products presented
          on the Site can be obtained at our points of sale, which are managed
          directly by Tamer Frères and its affiliates. For any further
          information, Tamer Frères invites you to contact an official point of
          sale or to contact us directly using the “contact us” form. Tamer
          Frères reserves the right to modify the models presented on the Site
          at any time.<div className="conditiontime">
            {" "}
            Accessing our Site
          </div>{" "}
          We do not guarantee that our Site, or any content on it, will always
          be available or be uninterrupted. Access to our Site is not permanent.
          We may suspend, withdraw, discontinue or change all or any part of our
          Site without prior notice. We will not be liable to you if for any
          reason whatsoever our Site is unavailable at any time or for any
          period. You are responsible for accessing our Site. The Site is
          operated and administered under the laws of Lebanon. Tamer Frères
          makes no representation or other commitment that the Site is
          appropriate for use or access in or from locations, territories,
          countries or jurisdictions other than Lebanon. If you access the Site
          from a location outside of Lebanon, you are responsible for compliance
          with all applicable local laws and regulations.
          <div className="conditiontime">No Reliance on Information</div> The
          content on our Site is provided for general information only. It is
          not intended to amount to investment advice on which you should rely.
          The products and services available on our Site may not be appropriate
          for your requirements and circumstances. You must obtain professional
          or specialist advice before taking, or refraining from, any action on
          the basis of the content on our Site.
          <div className="conditiontime"> Viruses</div> We do not guarantee that
          our Site will be secure or free from bugs or viruses. You are
          responsible for configuring your information technology, computer
          programs and platform in order to access our Site. You should use your
          own virus protection software. You must not misuse our Site by
          knowingly introducing viruses, worms, logic bombs or other material
          which is malicious or technologically harmful. You must not attempt to
          gain unauthorized access to our Site, the server on which our Site is
          stored or any server, computer or database connected to our Site. You
          must not attack our Site via a denial-of-service attack or a
          distributed denial-of service attack. In the event of such a breach,
          your right to use our Site will cease immediately without prior
          notice. This action will not deprive us to ask you for compensation
          and/or take any other action against you.{" "}
          <div className="conditiontime"> Limitation of Liability</div> We will
          use reasonable efforts to include accurate, complete and up-to-date
          information on this Site, but Tamer Frères makes no warranties or
          representations as to its accuracy, completeness or frequency that it
          is updated. However, please note that any of the content on our Site
          may be out of date at any time, and we are under no obligation to
          update it. To the fullest extent permitted by law, neither Tamer
          Frères, nor its affiliates shall be liable in any manner whatsoever
          for any direct, indirect or consequential damages, including loss of
          profits or third-party rights, which may result in particular, but
          without limitation, from:
          <ol>
            <li>
              information or photographs available on our Site; although Tamer
              Frères makes every effort to update the information available on
              the Site, it gives no guarantee, express or tacit, concerning the
              Content of the Site, (including product photographs, prices and
              descriptions) which are indicative and have no contractual value.
            </li>
            <li> the temporary inaccessibility of our Site.</li>{" "}
            <li>
              a malfunction of the internet network affecting access to our
              Site.
            </li>{" "}
            <li>
              {" "}
              a malfunction or misuse of the user’s device or any other
              equipment.{" "}
            </li>{" "}
            <li>
              a defect, loss, delay or error in data transmission beyond the
              control of Tamer Frères or its affiliates.
            </li>{" "}
            <li>
              your use of any material presented on our Site (including, without
              limitation, the infringement of the rights of third parties).
            </li>
            <li>
              {" "}
              the content of information on a third-party’s Site to which our
              Site may refer by hypertext links.
            </li>
            <li>
              {" "}
              a virus damaging your computer equipment or any other property as
              a result of your accessing or consulting the Site or downloading
              Content from the Site.
            </li>
          </ol>{" "}
          <div className="conditiontime"> No License</div>
          Nothing in this Site shall be construed as conferring by implication,
          estoppels or otherwise, any license or right to use in any form or
          manner any patent, copyright or trademark of Tamer Frères its
          subsidiaries or affiliates. Please be advised that Tamer Frères,
          protects its intellectual property rights and its subsidiaries or
          affiliates to the fullest extent of the law.
          <div className="conditiontime">User Warranty</div>
          You warrant that you are legally permitted to access and view the
          content of our Site and any other Site affiliated to our Site in the
          jurisdiction from which you are accessing it. You agree to indemnify,
          defend and hold us and our affiliates, partners, subsidiaries,
          officers, directors, employees and agents immediately harmless, from
          and against all loss, liability, claim, demand, judgment, damages,
          expenses or other loss including reasonable legal fees and lawyer’s
          remunerations asserted by or awarded to any third party and relating
          to your access to or use of the Site. We reserve the right to assume
          the exclusive defense and control of any matter subject to
          indemnification by you, which shall not excuse your indemnity or
          compensation obligations.
          <div className="conditiontime">Links and Resources in our Site</div>
          Where our Site contains links to other websites and resources provided
          by third parties, these links are provided for your information only.
          No endorsement by us of such third parties, or any of their products
          or services, is expressed or implied by the provision of such links.
          We have no control over the contents of those websites or resources
          and are not responsible for them. We will not be liable for any loss
          or damage that may arise from your use of them and you will be
          accessing them at your own full responsibility.
          <div className="conditiontime">Applicable Law </div>These terms of
          use, their subject matter and their formation, are governed by the
          laws of Lebanon. You and we both agree that the competent courts of
          Lebanon will have exclusive jurisdiction in relation to these terms of
          use irrelevant from which country you access our Site.
          <div className="conditiontime"> Miscellaneous</div> These Site usage
          terms constitute the entire agreement between Tamer Frères and you
          relating to the subject matter herein and you agree to indemnify Tamer
          Frères from any claims or damages resulting from your failure to abide
          by these Terms of Use.
          <div className="conditiontime">Contact Us</div> Any request relating
          to these Terms of Use, to the use or to the Content of the Site kindly
          email info@tamer-group.com.
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
