import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  homevideo: [],
  feature: [],
  bestseller: [],
  brandfeature: [],
  story: [],
  ourPartner: [],
  preorderrequest: [],
  bestSeller: [],
  loading: false,
  error: null,
  makeapp: [],

  onestory: [],

  favorite: [],
  addfavorite: [],
  deletefavorite: [],
  movetofavorite: [],
};

const HomeSlice = createSlice({
  name: "Home",
  initialState,
  reducers: {
    videoRequest(state, action) {
      state.loading = true;
    },
    videoSuccess(state, action) {
      state.loading = false;
      state.homevideo = action.payload;
    },
    videoFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    ourPartnerRequest(state, action) {
      state.loading = true;
    },
    ourPartnerSuccess(state, action) {
      state.loading = false;
      state.ourPartner = action.payload;
    },
    ourPartnerFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    featureRequest(state, action) {
      state.loading = true;
    },
    featureSuccess(state, action) {
      state.loading = false;
      state.feature = action.payload;
    },
    featureFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    storyRequest(state, action) {
      state.loading = true;
    },
    storySuccess(state, action) {
      state.loading = false;
      state.story = action.payload;
    },
    storyFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    brandfeatureRequest(state, action) {
      state.loading = true;
    },
    brandfeatureSuccess(state, action) {
      state.loading = false;
      state.brandfeature = action.payload;
    },
    brandfeatureFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    makeappRequest(state, action) {
      state.loading = true;
    },
    makeappSuccess(state, action) {
      state.loading = false;
      state.makeapp = action.payload;
    },
    makeappFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    preorderrequestRequest(state, action) {
      state.loading = true;
    },
    preorderrequestSuccess(state, action) {
      state.loading = false;
      state.preorderrequest = action.payload;
    },
    preorderrequestFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    onestoryRequest(state, action) {
      state.loading = true;
    },
    onestorySuccess(state, action) {
      state.loading = false;
      state.onestory = action.payload;
    },
    onestoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    favoriteRequest(state, action) {
      state.loading = true;
    },
    favoriteSuccess(state, action) {
      state.loading = false;
      state.favorite = action.payload;
    },
    favoriteFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    addfavoriteRequest(state, action) {
      state.loading = true;
    },
    addfavoriteSuccess(state, action) {
      state.loading = false;
      state.addfavorite = action.payload;
    },
    addfavoriteFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    deletefavoriteRequest(state, action) {
      state.loading = true;
    },
    deletefavoriteSuccess(state, action) {
      state.loading = false;
      state.deletefavorite = action.payload;
    },
    deletefavoriteFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    movetofavoriteRequest(state, action) {
      state.loading = true;
    },
    movetofavoriteSuccess(state, action) {
      state.loading = false;
      state.movetofavorite = action.payload;
    },
    movetofavoriteFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    bestSellerRequest(state, action) {
      state.loading = true;
    },
    bestSellerSuccess(state, action) {
      state.loading = false;
      state.bestSeller = action.payload;
    },
    bestSellerFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const HomeAction = HomeSlice.actions;
export default HomeSlice;
