import React from "react";
import "./WatchesViewCard.css";
import { useNavigate } from "react-router-dom";

function WatchesViewCard(props) {
  const navigate = useNavigate();

  // Limiting props.name to 250 characters and adding three dots if it exceeds
  const truncatedName =
    props.name?.length > 200
      ? (
        <span>
          {props.name.slice(0, 100)}
          <br></br>
          <br></br>

          <a href="#" className="see-more-link"> See More...</a>
        </span>
      )
      : props.name;




  return (
    <div
      className="Main_card_div"
      onClick={() => {
        if (props.type === "Pre-o") {
          navigate(`/preo/singleproduct/${props.link}`);
        } else {
          navigate(`/product/productdetails/${props.link}`);
        }
      }}

    >
      <div className="card_div">
        <div className={props.quantity_limit > 0 ? "card_image_div" : "card_image_div no-width"}>
          <img
            src={props.image}
            alt="watch"
            className="card_image"
            draggable={false}

          />

        </div>

        {props.quantity_limit <= 0 && props.id !== 1 ? (
          <div className="out-of-stock-message">OUT OF STOCK</div>
        ) :
          <div className="card_text_div">
            <div className="card_text1">{props.brand}</div>
            {props.id !== null && props.id !== 1 ? (
              <div className="card_text3">
                {props.price && `${props.price}$`}
              </div>
            ) : null}
            {truncatedName === "NULL" ? "" : <div className="card_text2">{truncatedName}</div>}

          </div>
        }
      </div>
    </div>
  );
}

export default WatchesViewCard;
