import React from "react";
import ReactDOM from "react-dom"; // Import react-dom instead of react-dom/client
import "./index.css";
import App from "./App";

import axios from "axios";
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
