import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setpayment } from "./../../Redux/Gift/GiftAction";
import { getoneproduct } from "./../../Redux/Products/ProductsAction";
import { AddGift } from "../../Redux/Gift/GiftAction";
import { useParams } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "react-notifications/lib/notifications.css";
import isAuth from "./../../Utils/isAuth";
import "./Gift.css";
import BillingShippings from "../../Components/Billing&Shipping/BillingShipping";
import Loader from "../../Components/Loader/Loader";

function Gift() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [billingID, setBillingID] = useState("");
  const [shippingID, setShippingID] = useState("");
  const [special_instructions, setSpecial_instructions] = useState("");

  const [onepro, setonepro] = useState([]);
  const { oneproduct, loading } = useSelector((state) => state.ProductsReducer);
  const { gift, loading1 } = useSelector((state) => state.GiftReducer);
  const [click, setclick] = useState(false);
  const handleBillingChange = (billingID) => {
    setBillingID(billingID);
  };

  const handleShippingChange = (shippingID) => {
    setShippingID(shippingID);
  };

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/sign_in");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getoneproduct(id));
    setonepro(oneproduct);
  }, [dispatch]);

  const handleGiftPayment = (
    id,
    special_instructions,
    billingID,
    shippingID
  ) => {
    dispatch(AddGift(id, special_instructions, billingID, shippingID));
    setclick(true);
  };

  const handleClick = () => {
    if (!billingID || !shippingID) {
      NotificationManager.error(
        " Both Billing and Shipping Info are Required "
      );
    }
    if (!special_instructions) {
      NotificationManager.error("Special Instructions are Required");
    } else {
      // Handle payment logic here

      handleGiftPayment(id, special_instructions, billingID, shippingID);
    }
  };

  useEffect(() => {
    if (gift && click && typeof gift == "string") {
      window.open(gift, "_blank"); // Use window.open to open the link in a new tab
      setclick(false);
    }
  }, [gift]);

  return (
    <div>
      <NotificationContainer />
      <div className="my_cart">
        <div className="inside">
          <div className="homecontainer">
            <div className="home">
              {" "}
              HOME &nbsp; &nbsp;/ &nbsp; &nbsp;
              <span className="cartname"> Gift</span>
            </div>
            <div className="myCart">Gift</div>
          </div>
        </div>
      </div>

      {loading || loading1 ? (
        <Loader />
      ) : (
        <div className="billingandshipping">
          <div className="columndiv">
            <div className="gift-in">
              <h1 className="gift-title">Special Instructions</h1>
              <textarea
                required
                value={special_instructions}
                type="text"
                id="special_instructions"
                placeholder="Special Instructions"
                className="special_instructions"
                onChange={(e) => {
                  setSpecial_instructions(e.target.value);
                }}
              />
            </div>
            <BillingShippings
              onBillingChange={handleBillingChange}
              onShippingChange={handleShippingChange}
            />
          </div>
          <div className="cart_total">
            <div className="cart_total_text">CART TOTAL</div>
            <div className="cart_subtotals_text">
              <div className="cart_subtotals_text1">Subtotal</div>
              <div className="cart_subtotals_text2">
                {parseFloat(oneproduct?.data?.data?.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}$
              </div>
            </div>
            <div className="cart_underline" />
            <div className="cart_subtotals_text">
              <div className="cart_subtotals_text1">SHIPPING</div>
              <div className="cart_subtotals_text3">Delivery Charge: $5.00</div>
            </div>
            <div className="cart_underline" />
            <div className="cart_subtotals_text">
              <div className="cart_subtotals_text1">Total</div>
              <div className="cart_subtotals_text2">
                {(parseFloat(oneproduct?.data?.data?.price) + 5).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}$
              </div>
            </div>
            <div className="cart_button_text">
              <button className="cart_button" onClick={handleClick}>
                PROCEED TO CONFIRMATION
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Gift;
