import { React, useState, useEffect } from "react";
import "./Footer.css";
import logo2 from "../../assets/Images/Header/Logo2.png";
import { RiFacebookCircleLine } from "react-icons/ri";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GetBrands } from "../../Redux/Brand/BrandActions";
import { useDispatch, useSelector } from "react-redux";
import { GetCategories } from "../../Redux/Category/CategoryActions";

function Footer() {
  const instagramLink = "https://www.instagram.com/tamer.freres/?hl=en";
  const facebookLink =
    "https://www.facebook.com/profile.php?id=100089038916190";
  const youtubeLink = "https://youtube.com/@TamerFreres";
  const linkedInLink = "https://www.linkedin.com/company/tamer-freres";
  const dispatch = useDispatch();
  const { brands } = useSelector((Store) => Store.BrandReducer);
  const { categories } = useSelector((Store) => Store.CategoryReducer);

  useEffect(() => {
    dispatch(GetBrands());
    dispatch(GetCategories());
  }, []);

  return (
    <div className="footer">
      <div className="main-footer ">
        <div className="container1">
          <div className="row">
            <img src={logo2} alt="logo" className="logo2" />
          </div>
          <div className="row">
            <div className="row_title">OUR BRANDS</div>
            <div className="row_content">
              {brands.map((brand, index) => (
                <div className="row_content_item" key={index}>
                  <Link
                    to={`/products?brand=${brand?.id}`}
                    className="row_content_item"
                  >
                    {brand?.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="row_title">OUR PRODUCTS</div>
            <div className="row_content">
              {categories.slice(0, 7).map((Category) => (
                <div className="row_content_item" key={Category?.id}>
                  <Link
                    to={`/products?category=${Category?.id}`}
                    className="row_content_item"
                  >
                    {Category?.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="row_title">CUSTOMER SERVICE</div>
            <div className="row_content">
              {/* <div className="row_content_item">Our Services</div>
              <div className="row_content_item">
                <Link to="/track_order" className="row_content_item">
                  Track Your Services
                </Link>
              </div> */}
              {/* <div className="row_content_item">Visit Our Stores</div> */}
              <div className="row_content_item">
                <Link to="/contact_us" className="row_content_item">
                  Contact Us
                </Link>
              </div>
              {/* <div className="row_content_item">FAQ</div> */}
            </div>
          </div>
          <div className="row">
            <div className="row_title">USEFUL INFORMATION</div>
            <div className="row_content">
              <div className="row_content_item">
                <Link to="/careers" className="row_content_item">
                  Careers
                </Link>
              </div>
              <div className="row_content_item">
                <Link to="/terms_and_conditions" className="row_content_item">
                  Terms of Use
                </Link>
              </div>
              {/* <div className="row_content_item">
                <Link to="/" className="row_content_item">
                  Home Page
                </Link>
              </div> */}
              <div className="socialmedia">
                <div className="header_icon">
                  <a
                    href={facebookLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiFacebookCircleLine className="header-icons" size={30} />
                  </a>
                </div>
                <div className="header_icon">
                  <a
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsInstagram className="header-icons" size={25} />
                  </a>
                </div>
                <div className="header_icon">
                  <a
                    href={youtubeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsYoutube className="header-icons" size={25} />
                  </a>
                </div>
                <div className="header_icon">
                  <a
                    href={linkedInLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsLinkedin className="header-icons" size={25} />
                  </a>
                </div>
                <div className="header_icon">
                  <a
                    href={linkedInLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiOutlineWhatsApp className="header-icons" size={25} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_foot">
        <div className="rights_div">
          © TAMER FRERES SAL 2023. All Rights reserved
        </div>{" "}
      </div>
    </div>
  );
}

export default Footer;
