import React, { useState, useEffect } from "react";
import "./ProductDetails.css";
import { GrLocation } from "react-icons/gr";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getoneproduct } from "./../../Redux/Products/ProductsAction";
import { addToCard } from "./../../Redux/Card/CardActions";
import { useParams } from "react-router-dom";
import PopUp from "../../Components/PopUp/PopUp";
import BuyPreorder from "../BuyPreorder/BuyPreorder";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import DetailAttribute from "./DetailAttribute";
import Heart from "./../../assets/Images/Cart/Vector (2).png";
import { GoGift } from "react-icons/go";

import { AiFillHeart } from "react-icons/ai";
import {
  getFavorite,
  addfavorite,
  deletefavorite,
} from "./../../Redux/homepage/HomePageAction";
import Loader from "../../Components/Loader/Loader";
import WatchesViewCard from "../../Components/WatchesViewCard/WatchesViewCard";
import isAuth from "../../Utils/isAuth";
import { useNavigate } from "react-router-dom";

function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { oneproduct, loading } = useSelector((state) => state.ProductsReducer);
  const { card, error } = useSelector((state) => state.CardReducer);
  const { favorite } = useSelector((state) => state.HomeReducer);
  const [addToCardAction, setAddToCardAction] = useState(false);
  const [onepro, setonepro] = useState([]);
  const [mainpic, setMainpic] = useState();
  const [proBrand, setProBrand] = useState();
  const handleOpen = (open) => {
    setOpen(!open);
  };

  console.log(onepro);

  useEffect(() => {
    dispatch(getoneproduct(id));
    dispatch(getFavorite());
  }, [dispatch, id]);


  useEffect(() => {
    let actionDispatched = false;
    if (addToCardAction && !loading) {
      if (!actionDispatched && error === null && isAuth()) {
        NotificationManager.success("Cart created successfully", "Success");
        actionDispatched = true;
      } else if (error) {
        NotificationManager.error(error);
      }
      setAddToCardAction(false);
    }
  }, [loading, card, error, addToCardAction]);
  const [relatedproduct, setrelatedproduct] = useState([]);

  useEffect(() => {
    setonepro(oneproduct?.data?.data);
    setProBrand(oneproduct?.data?.data.brand?.name);
    setrelatedproduct(oneproduct?.data?.relatedProducts);
  }, [oneproduct]);

  useEffect(() => {
    setMainpic(onepro?.img_url);
  }, [dispatch, onepro]);

  const handleclose = () => {
    setOpen(false);
  };
  const succes = () => {
    NotificationManager.success(
      "Request created successfully , We Will contact you soon.",
      "Success"
    );
  };

  const handleFavorite = () => {
    if (!isAuth()) {
      NotificationManager.error("You need to login first");
      return;
    }
    dispatch(addfavorite(id));
    setFavorite(true);
    NotificationManager.success(
      "Item added to Wishlist successfully",
      "Success"
    );
  };
  const handleDeleteFavorite = () => {
    if (!isAuth()) {
      NotificationManager.error("You need to login first");
      return;
    }
    setFavorite(false);
    dispatch(deletefavorite(favoriteid));
    NotificationManager.warning(
      "Item Removed from Wishlist successfully",
      "Success"
    );
  };

  const [favoriteitem, setFavorite] = useState(false);
  const [favoriteid, setFavoriteid] = useState();

  useEffect(() => {
    {
      favorite?.data?.data?.map((item) => {
        if (item?.product_id == id) {
          setFavorite(true);
          setFavoriteid(item?.id);
        } else {
          setFavorite(false);
        }
      });
    }
  }, [id, favorite]);

  useEffect(() => {
    if (onepro?.description === "NULL") { setActiveTab("details") }
  }, [onepro?.description])
  const [activeTab, setActiveTab] = useState('description');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };


  return (
    <div>
      {open && (
        <PopUp
          setOpen={setOpen}
          body={<BuyPreorder id={id} onClose={handleclose} Success={succes} />}
        ></PopUp>
      )}
      <NotificationContainer />
      <div className="rout">
        <div className="homeroute">HOME &nbsp;&nbsp;/</div>
        <div className="watchroute"> &nbsp;&nbsp; Brands</div>
        <div className="productroute">
          {" "}
          &nbsp;&nbsp;/ &nbsp;&nbsp; {onepro?.brand?.name}{" "}
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="container">
            <div className="image">
              <div className="imagescoloumn">
                {onepro?.ProductImages?.map((product, index) => (
                  <img
                    src={product?.image}
                    className="smallimage"
                    key={index}
                    onClick={() => setMainpic(product?.image)}
                  />
                ))}
              </div>
              <div>
                <img src={mainpic} className="image-conto" />
              </div>
            </div>
            <div className="description">
              <div>
                <img
                  src={
                    process.env.REACT_APP_API + "/brand/" + onepro?.brand?.img_url
                  }
                  className="brandpic"
                />
              </div>
              <div className="watchname">{onepro?.title}</div>

              <div className="cont1">
                <div className="soldby">SKU:</div>
                <div className="partner-item-container">
                  {/* <div className="partner">{onepro?.sold_by}</div> */}
                  <div className="item-code">{onepro?.SKU}</div>
                </div>
              </div>
              {onepro?.brand?.name !== "Audemars Piguet" && (
                <div className="price">{onepro?.price}$</div>
              )}

              {/* <div className="descriptiontext">{onepro?.desc}</div> */}
              <div className="threematerial1">

                {oneproduct?.data?.data.quantity_limit > 0 ?
                  <>{onepro?.brand?.name !== "Audemars Piguet" && (
                    <button
                      className="inquiry1"
                      onClick={() => {
                        if(isAuth()){
                          dispatch(addToCard(id));
                          setAddToCardAction(true);
                        }else{
                          NotificationManager.error("You need to login first");
                        }

                      }}
                    >
                      ADD TO CART
                    </button>
                  )}</> : <>{onepro?.brand?.name !== "Audemars Piguet" && <p>Out Of Stock</p>}</>}


                {favoriteitem && (
                  <div onClick={() => handleDeleteFavorite()} className="heart">
                    <AiFillHeart size={30} />
                  </div>
                )}
                {!favoriteitem && (
                  <div onClick={() => handleFavorite()} className="heart">
                    <AiOutlineHeart size={30} />
                  </div>
                )}
              </div>
            </div>
            <br></br>
            <div className="table-pro">
              <div className="navbar-pro">

                {onepro?.description === "NULL" ? <></> :

                  <a onClick={() => handleTabChange('description')} className={`navbar-item-pro ${activeTab === 'description' ? 'active' : ''}`}>Description</a>
                }


                <a onClick={() => handleTabChange('details')} className={`navbar-item-pro ${activeTab === 'details' ? 'active' : ''}`}>Details</a>
              </div>

              <div className="inside-tab">
                <div className="carousel-content">
                  {activeTab === 'description' && (
                    <>

                      <div className="details">{onepro?.description}</div>
                    </>
                  )}

                  {activeTab === 'details' && (
                    <>

                      <DetailAttribute brandName={proBrand} oneproduct={oneproduct} />
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="container2">
              <div className="title">Shipping & Returns</div>
              <div className="details details-block">
                This item can be returned for credit card refund.<br></br>
                <br></br>Return Authorization requests must be made within 14 days
                of shipment and the item must be returned within 21 days of
                original shipment.<br></br>
                <br></br>
                For more details
                <br></br> <a style={{ textDecoration: 'underline' }} href="/policy">See the Return Policy</a>
              </div>
              <div className="title">Authentication</div>
              <div className="details">
                We authenticate every item with a rigorous process overseen by
                experts<br></br>
                <br></br> Learn more & see our authentication process.
                Photos are of the actual item in our possession.
              </div>
              {/* <div className="title">Want to sell your item?</div>
              Contact Us */}
            </div>
          </div>
        </div>
      )
      }
      <div className="SecondContainer">
        <div className="first">
          <GrLocation size={50} />
          <div className="titlecont">BUY FROM A STORE</div>
          <div className="titlecont2">
            From planter material to style options,discover which.
          </div>
          <div>
            <button className="button" onClick={() => handleOpen(open)}>
              CLICK & COLLECT
            </button>
          </div>
        </div>
        {onepro?.brand?.name !== "Audemars Piguet" && (
          <div className="first">
            <GoGift size={50} />
            <div className="titlecont">Send as a gift?</div>
            <div className="titlecont2">
              From planter materials to style options, discover which.{" "}
            </div>
            <div>
              <button className="button" onClick={() => navigate(`/Gift/${id}`)}>

                send as a gift{" "}
              </button>
            </div>
          </div>

        )}

      </div>

      <div className="heading_titles">
        <div className="heading_title_2">related products</div>
      </div>
      <div className="horizontal_divider_div small_divider">
        <div className="horizontal_divider"></div>
      </div>

      <div className="watching_info_display">
        {relatedproduct?.map((product, index) => (
          <WatchesViewCard
            key={index}
            link={product.id}
            image={product.img_url} // Assuming the image is part of the product object

            brand={product.title}
            price={product.price}
          />
        ))}
      </div>
    </div >
  );
}

export default ProductDetails;
