import axios from "axios";
import { ProductsAction } from "./ProductsReducer";

export const getcategory = () => async (dispatch) => {
  try {
    dispatch(ProductsAction.CategoryRequest());
    const data = await axios.get(process.env.REACT_APP_API + "/category");
    dispatch(ProductsAction.CategorySuccess(data));
  } catch (error) {
    dispatch(ProductsAction.CategoryFail(error));
  }
};

export const GetAllProducts =
  (limit, page, filter, type = "Shop") =>
  async (dispatch) => {
    try {
      dispatch(ProductsAction.ProductsRequest());
      const { data } = await axios.get(
        process.env.REACT_APP_API +
          "/product/filter?page=" +
          page +
          "&limit=" +
          limit +
          "&type=" +
          type +
          "&" +
          filter
      );

      dispatch(ProductsAction.ProductsSuccess(data));
    } catch (error) {
      dispatch(ProductsAction.ProductsFail(error));
    }
  };

export const getProduct = (categoryName, type) => async (dispatch) => {
  try {
    dispatch(ProductsAction.productpreRequest());

    let queryString = "";
    if (categoryName.length > 0) {
      queryString = "category=" + categoryName + "&";
    }

    const data = await axios.get(
      process.env.REACT_APP_API +
        "/product/filter?" +
        queryString +
        "type=" +
        type
    );

    dispatch(ProductsAction.productpreSuccess(data));
  } catch (error) {
    dispatch(ProductsAction.productpreFail(error));
  }
};
export const getoneproduct = (id) => async (dispatch) => {
  try {
    dispatch(ProductsAction.oneproductRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/product/single/" + id
    );
    dispatch(ProductsAction.oneproductSuccess(data));
  } catch (error) {
    dispatch(ProductsAction.oneproductFail(error));
  }
};
export const getoneproductpre = (id) => async (dispatch) => {
  try {
    dispatch(ProductsAction.oneproductpreRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/pre_product/single/" + id
    );
    dispatch(ProductsAction.oneproductpreSuccess(data));
  } catch (error) {
    dispatch(ProductsAction.oneproductpreFail(error));
  }
};

export const getproductbuycategory = (id) => async (dispatch) => {
  try {
    dispatch(ProductsAction.CategoryproductpreRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/pre_product/category/" + id
    );
    dispatch(ProductsAction.CategoryproductpreSuccess(data));
  } catch (error) {
    dispatch(ProductsAction.CategoryproductpreFail(error));
  }
};

export const getfeatureproducg = (id) => async (dispatch) => {
  try {
    dispatch(ProductsAction.featureprodRequest());
    const data = await axios.get(
      process.env.REACT_APP_API + "/featured_product/" + id
    );
    dispatch(ProductsAction.featureprodSuccess(data));
  } catch (error) {
    dispatch(ProductsAction.featureprodFail(error));
  }
};
