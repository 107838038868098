import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  order: [],
  loading: false,
  error: null,
};

const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    OrdersRequest(state, action) {
      state.loading = true;
    },
    OrdersSuccess(state, action) {
      state.loading = false;
      state.orders = action.payload;
    },
    OrdersFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    OrderRequest(state, action) {
      state.loading = true;
    },
    OrderSuccess(state, action) {
      state.loading = false;
      state.order = action.payload;
    },
    OrderFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const OrderAction = OrderSlice.actions;
export default OrderSlice;
