import React from "react";
import "./ClickAndCollect.css";

function ClickAndCollect() {
  return (
    <>
      <div className="click_and_collect_main_container">
        <div className="click_and_collect_main_tite">
          Click and Collect Request
        </div>
        <div className="click_and_collect_content">
          <label className="click_and_collect_labels">First Name</label>
          <input
            type="text"
            placeholder="First Name"
            className="click_and_collect_input"
          />
          <label className="click_and_collect_labels">Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            className="click_and_collect_input"
          />

          <label className="click_and_collect_labels">Email Address</label>
          <input
            type="text"
            placeholder="Email Address"
            className="click_and_collect_select"
          />
        </div>
        <label className="click_and_collect_labels">Special Request</label>
        <textarea
          type="text"
          placeholder="Special Request"
          className="click_and_collect_input click_and_collect_textarea"
        />
        <button className="click_and_collect_submit_btn">SUBMIT</button>
      </div>
    </>
  );
}

export default ClickAndCollect;
