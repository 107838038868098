import "./App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PageSwitch from "./Pages/PageSwitch";
import Store from "./Redux/Store";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import { useEffect } from "react";
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Provider store={Store}>
          <PageSwitch />
        </Provider>
      </Router>
    </div>
  );
}

export default App;
